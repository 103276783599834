import Vue from 'vue'
import {
  BootstrapVue,
  BIcon,
  BIconArrowUp,
  BIconArrowDown,
  BIconArrowLeft,
  BIconArrowRight,
  BIconChevronDown,
  BIconChevronUp,
  BIconChevronLeft,
  BIconChevronRight,
  BIconChevronDoubleDown,
  BIconChevronDoubleUp,
  BIconChevronDoubleLeft,
  BIconChevronDoubleRight,
  BIconTrash,
  BIconTrashFill,
  BIconCheckCircle,
  BIconCheckCircleFill,
  BIconTruck,
  BIconInfoCircle,
  BIconInfoCircleFill,
  BIconHeartFill,
  BIconHeart,
  BIconCheck,
  BIconX,
  BIconEye,
  BIconEyeSlash,
  BIconPencilFill,
  BIconSticky,
  BIconStickies,
  BIconClockFill,
  BIconArrowCounterclockwise,
  BIconStopFill,
  BIconFileEarmark,
  BIconFileEarmarkFill,
  BIconFileEarmarkExcel,
  BIconSearch,
  BIconStarFill,
  BIconFunnel,
  BIconGrid3x3GapFill,
  BIconList,
  BIconListUl,
  BIconDash,
  BIconPlus,
  BIconPlusCircleFill,
  BIconPerson,
  BIconPersonLinesFill,
  BIconGlobe2,
  BIconGeoAlt,
  BIconGearFill,
  BIconJournalText,
  BIconBox,
  BIconCameraFill,
  BIconPrinter,
  BIconExclamationTriangleFill,
  BIconDashCircleFill,
  BIconCardText,
  BIconQuestionCircle,
  BIconCircle,
  BIconArrowBarLeft,
  BIconCaretDownFill,
  BIconTelephoneFill,
  BIconExclamationCircleFill,
  BIconChatLeftDotsFill,
} from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.component('BIcon', BIcon)
Vue.component('BIconArrowUp', BIconArrowUp)
Vue.component('BIconArrowDown', BIconArrowDown)
Vue.component('BIconArrowLeft', BIconArrowLeft)
Vue.component('BIconArrowRight', BIconArrowRight)
Vue.component('BIconChevronDown', BIconChevronDown)

Vue.component('BIconChevronUp', BIconChevronUp)
Vue.component('BIconChevronLeft', BIconChevronLeft)
Vue.component('BIconChevronRight', BIconChevronRight)
Vue.component('BIconChevronDoubleDown', BIconChevronDoubleDown)
Vue.component('BIconChevronDoubleUp', BIconChevronDoubleUp)
Vue.component('BIconChevronDoubleLeft', BIconChevronDoubleLeft)
Vue.component('BIconChevronDoubleRight', BIconChevronDoubleRight)
Vue.component('BIconTrash', BIconTrash)
Vue.component('BIconTrashFill', BIconTrashFill)
Vue.component('BIconCheckCircle', BIconCheckCircle)
Vue.component('BIconCheckCircleFill', BIconCheckCircleFill)
Vue.component('BIconTruck', BIconTruck)
Vue.component('BIconInfoCircle', BIconInfoCircle)
Vue.component('BIconInfoCircleFill', BIconInfoCircleFill)
Vue.component('BIconHeartFill', BIconHeartFill)
Vue.component('BIconHeart', BIconHeart)
Vue.component('BIconCheck', BIconCheck)
Vue.component('BIconX', BIconX)
Vue.component('BIconEye', BIconEye)
Vue.component('BIconEyeSlash', BIconEyeSlash)
Vue.component('BIconPencilFill', BIconPencilFill)
Vue.component('BIconSticky', BIconSticky)
Vue.component('BIconStickies', BIconStickies)
Vue.component('BIconClockFill', BIconClockFill)
Vue.component('BIconArrowCounterclockwise', BIconArrowCounterclockwise)
Vue.component('BIconStopFill', BIconStopFill)
Vue.component('BIconFileEarmark', BIconFileEarmark)
Vue.component('BIconFileEarmarkFill', BIconFileEarmarkFill)
Vue.component('BIconFileEarmarkExcel', BIconFileEarmarkExcel)
Vue.component('BIconSearch', BIconSearch)
Vue.component('BIconStarFill', BIconStarFill)
Vue.component('BIconFunnel', BIconFunnel)
Vue.component('BIconGrid3x3GapFill', BIconGrid3x3GapFill)
Vue.component('BIconList', BIconList)
Vue.component('BIconListUl', BIconListUl)
Vue.component('BIconDash', BIconDash)
Vue.component('BIconPlus', BIconPlus)
Vue.component('BIconPlusCircleFill', BIconPlusCircleFill)
Vue.component('BIconPerson', BIconPerson)
Vue.component('BIconPersonLinesFill', BIconPersonLinesFill)
Vue.component('BIconGlobe2', BIconGlobe2)
Vue.component('BIconGeoAlt', BIconGeoAlt)
Vue.component('BIconGearFill', BIconGearFill)
Vue.component('BIconJournalText', BIconJournalText)
Vue.component('BIconBox', BIconBox)
Vue.component('BIconCameraFill', BIconCameraFill)
Vue.component('BIconPrinter', BIconPrinter)
Vue.component('BIconExclamationTriangleFill', BIconExclamationTriangleFill)
Vue.component('BIconDashCircleFill', BIconDashCircleFill)
Vue.component('BIconCardText', BIconCardText)
Vue.component('BIconQuestionCircle', BIconQuestionCircle)
Vue.component('BIconCircle', BIconCircle)
Vue.component('BIconArrowBarLeft', BIconArrowBarLeft)
Vue.component('BIconCaretDownFill', BIconCaretDownFill)
Vue.component('BIconTelephoneFill', BIconTelephoneFill)
Vue.component('BIconExclamationCircleFill', BIconExclamationCircleFill)
Vue.component('BIconChatLeftDotsFill', BIconChatLeftDotsFill)
