import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5581072a = () => interopDefault(import('../pages/403.vue' /* webpackChunkName: "pages/403" */))
const _2375ebaa = () => interopDefault(import('../pages/apply-now.vue' /* webpackChunkName: "pages/apply-now" */))
const _886a3b72 = () => interopDefault(import('../pages/biz.vue' /* webpackChunkName: "pages/biz" */))
const _66ecad6a = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _7453ad1c = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _8c03ac32 = () => interopDefault(import('../pages/changePassword.vue' /* webpackChunkName: "pages/changePassword" */))
const _4e2207e9 = () => interopDefault(import('../pages/discount-coupon/index.vue' /* webpackChunkName: "pages/discount-coupon/index" */))
const _c7e9d8da = () => interopDefault(import('../pages/example-authen/index.vue' /* webpackChunkName: "pages/example-authen/index" */))
const _0354cc3e = () => interopDefault(import('../pages/flash-sale-list/index.vue' /* webpackChunkName: "pages/flash-sale-list/index" */))
const _7e4dde8c = () => interopDefault(import('../pages/forgotpassword.vue' /* webpackChunkName: "pages/forgotpassword" */))
const _b4d4bac6 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _903d0d22 = () => interopDefault(import('../pages/newpassword.vue' /* webpackChunkName: "pages/newpassword" */))
const _3c59d6aa = () => interopDefault(import('../pages/notification/index.vue' /* webpackChunkName: "pages/notification/index" */))
const _7db4485f = () => interopDefault(import('../pages/otp.vue' /* webpackChunkName: "pages/otp" */))
const _636a32a0 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _42201caf = () => interopDefault(import('../pages/promotions/index.vue' /* webpackChunkName: "pages/promotions/index" */))
const _c601cc50 = () => interopDefault(import('../pages/redirect.vue' /* webpackChunkName: "pages/redirect" */))
const _02f0d5c2 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _86a26d52 = () => interopDefault(import('../pages/register-complete.vue' /* webpackChunkName: "pages/register-complete" */))
const _7d90f573 = () => interopDefault(import('../pages/request-credit.vue' /* webpackChunkName: "pages/request-credit" */))
const _8431c450 = () => interopDefault(import('../pages/rewards.vue' /* webpackChunkName: "pages/rewards" */))
const _6a009060 = () => interopDefault(import('../pages/store-location/index.vue' /* webpackChunkName: "pages/store-location/index" */))
const _3a571cf5 = () => interopDefault(import('../pages/verify.vue' /* webpackChunkName: "pages/verify" */))
const _9f8f64e0 = () => interopDefault(import('../pages/account/billing/index.vue' /* webpackChunkName: "pages/account/billing/index" */))
const _435903e9 = () => interopDefault(import('../pages/account/dashboard/index.vue' /* webpackChunkName: "pages/account/dashboard/index" */))
const _f8da10d6 = () => interopDefault(import('../pages/account/delivery/index.vue' /* webpackChunkName: "pages/account/delivery/index" */))
const _5500b33d = () => interopDefault(import('../pages/account/favorite/index.vue' /* webpackChunkName: "pages/account/favorite/index" */))
const _173f6bfe = () => interopDefault(import('../pages/account/information/index.vue' /* webpackChunkName: "pages/account/information/index" */))
const _310731cd = () => interopDefault(import('../pages/account/select-company/index.vue' /* webpackChunkName: "pages/account/select-company/index" */))
const _00184ca8 = () => interopDefault(import('../pages/account/user/index.vue' /* webpackChunkName: "pages/account/user/index" */))
const _72c319c0 = () => interopDefault(import('../pages/activity/store/index.vue' /* webpackChunkName: "pages/activity/store/index" */))
const _d2a7c4f2 = () => interopDefault(import('../pages/approver/b2b/index.vue' /* webpackChunkName: "pages/approver/b2b/index" */))
const _652c6e39 = () => interopDefault(import('../pages/company/dashboard/index.vue' /* webpackChunkName: "pages/company/dashboard/index" */))
const _f04e6764 = () => interopDefault(import('../pages/company/information.vue' /* webpackChunkName: "pages/company/information" */))
const _71c2b264 = () => interopDefault(import('../pages/company/news/index.vue' /* webpackChunkName: "pages/company/news/index" */))
const _34f0a696 = () => interopDefault(import('../pages/company/products/index.vue' /* webpackChunkName: "pages/company/products/index" */))
const _9f681d16 = () => interopDefault(import('../pages/company/setting/index.vue' /* webpackChunkName: "pages/company/setting/index" */))
const _723b88a9 = () => interopDefault(import('../pages/home/callback.vue' /* webpackChunkName: "pages/home/callback" */))
const _e1bae5f2 = () => interopDefault(import('../pages/order/history.vue' /* webpackChunkName: "pages/order/history" */))
const _d460abbe = () => interopDefault(import('../pages/payment/complete/index.vue' /* webpackChunkName: "pages/payment/complete/index" */))
const _6c428446 = () => interopDefault(import('../pages/payment/fail/index.vue' /* webpackChunkName: "pages/payment/fail/index" */))
const _831cc99e = () => interopDefault(import('../pages/account/billing/create/index.vue' /* webpackChunkName: "pages/account/billing/create/index" */))
const _3decdce8 = () => interopDefault(import('../pages/account/delivery/create/index.vue' /* webpackChunkName: "pages/account/delivery/create/index" */))
const _1b023e33 = () => interopDefault(import('../pages/account/new-register/address.vue' /* webpackChunkName: "pages/account/new-register/address" */))
const _41c120e5 = () => interopDefault(import('../pages/account/new-register/check-tax.vue' /* webpackChunkName: "pages/account/new-register/check-tax" */))
const _50fd10d6 = () => interopDefault(import('../pages/account/user/create/index.vue' /* webpackChunkName: "pages/account/user/create/index" */))
const _2370364c = () => interopDefault(import('../pages/company/news/create.vue' /* webpackChunkName: "pages/company/news/create" */))
const _6d39d402 = () => interopDefault(import('../pages/company/printing/cart/index.vue' /* webpackChunkName: "pages/company/printing/cart/index" */))
const _bc017f2a = () => interopDefault(import('../pages/company/printing/history/index.vue' /* webpackChunkName: "pages/company/printing/history/index" */))
const _26297cd9 = () => interopDefault(import('../pages/company/printing/template/index.vue' /* webpackChunkName: "pages/company/printing/template/index" */))
const _04fef025 = () => interopDefault(import('../pages/company/report/budget/index.vue' /* webpackChunkName: "pages/company/report/budget/index" */))
const _5f6fe7da = () => interopDefault(import('../pages/company/report/budget-printing/index.vue' /* webpackChunkName: "pages/company/report/budget-printing/index" */))
const _96960544 = () => interopDefault(import('../pages/company/report/category/index.vue' /* webpackChunkName: "pages/company/report/category/index" */))
const _3118c0cb = () => interopDefault(import('../pages/company/report/gr/index.vue' /* webpackChunkName: "pages/company/report/gr/index" */))
const _e1f3b778 = () => interopDefault(import('../pages/company/report/order/index.vue' /* webpackChunkName: "pages/company/report/order/index" */))
const _1e000ad4 = () => interopDefault(import('../pages/company/report/order-printing/index.vue' /* webpackChunkName: "pages/company/report/order-printing/index" */))
const _2e2121dc = () => interopDefault(import('../pages/company/setting/costcenter/index.vue' /* webpackChunkName: "pages/company/setting/costcenter/index" */))
const _281e1dc8 = () => interopDefault(import('../pages/company/user/admin/index.vue' /* webpackChunkName: "pages/company/user/admin/index" */))
const _5bb8e436 = () => interopDefault(import('../pages/company/user/create/index.vue' /* webpackChunkName: "pages/company/user/create/index" */))
const _370adb0e = () => interopDefault(import('../pages/company/user/manage/index.vue' /* webpackChunkName: "pages/company/user/manage/index" */))
const _133201b6 = () => interopDefault(import('../pages/universal-link/qr/login/index.vue' /* webpackChunkName: "pages/universal-link/qr/login/index" */))
const _60fc236f = () => interopDefault(import('../pages/company/setting/costcenter/create/index.vue' /* webpackChunkName: "pages/company/setting/costcenter/create/index" */))
const _555b568c = () => interopDefault(import('../pages/company/user/manage/setting/create/_id.vue' /* webpackChunkName: "pages/company/user/manage/setting/create/_id" */))
const _4c6852f0 = () => interopDefault(import('../pages/company/user/manage/setting/edit/_id.vue' /* webpackChunkName: "pages/company/user/manage/setting/edit/_id" */))
const _3464ac30 = () => interopDefault(import('../pages/company/printing/template/create/_id.vue' /* webpackChunkName: "pages/company/printing/template/create/_id" */))
const _42df6ab6 = () => interopDefault(import('../pages/company/setting/costcenter/edit/_id.vue' /* webpackChunkName: "pages/company/setting/costcenter/edit/_id" */))
const _6a1d3f92 = () => interopDefault(import('../pages/company/user/manage/setting/_id.vue' /* webpackChunkName: "pages/company/user/manage/setting/_id" */))
const _51281932 = () => interopDefault(import('../pages/account/billing/edit/_id.vue' /* webpackChunkName: "pages/account/billing/edit/_id" */))
const _5b1b4f42 = () => interopDefault(import('../pages/account/delivery/edit/_id.vue' /* webpackChunkName: "pages/account/delivery/edit/_id" */))
const _6981a1cb = () => interopDefault(import('../pages/account/user/edit/_id.vue' /* webpackChunkName: "pages/account/user/edit/_id" */))
const _4d629626 = () => interopDefault(import('../pages/approve/printing/validate/_id.vue' /* webpackChunkName: "pages/approve/printing/validate/_id" */))
const _5f776e13 = () => interopDefault(import('../pages/company/news/edit/_id.vue' /* webpackChunkName: "pages/company/news/edit/_id" */))
const _3a2fd180 = () => interopDefault(import('../pages/company/printing/complete/_id.vue' /* webpackChunkName: "pages/company/printing/complete/_id" */))
const _5558a94d = () => interopDefault(import('../pages/company/printing/order/_id.vue' /* webpackChunkName: "pages/company/printing/order/_id" */))
const _0af605d4 = () => interopDefault(import('../pages/company/products/create/_id.vue' /* webpackChunkName: "pages/company/products/create/_id" */))
const _d205f3bc = () => interopDefault(import('../pages/company/products/edit/_id.vue' /* webpackChunkName: "pages/company/products/edit/_id" */))
const _7f00118a = () => interopDefault(import('../pages/company/report/budget-printing/_id.vue' /* webpackChunkName: "pages/company/report/budget-printing/_id" */))
const _1b64b7cd = () => interopDefault(import('../pages/company/report/budget/_id.vue' /* webpackChunkName: "pages/company/report/budget/_id" */))
const _92693fca = () => interopDefault(import('../pages/company/user/edit/_id.vue' /* webpackChunkName: "pages/company/user/edit/_id" */))
const _0913c76a = () => interopDefault(import('../pages/account/order/_id.vue' /* webpackChunkName: "pages/account/order/_id" */))
const _601a936c = () => interopDefault(import('../pages/account/specialproducts/_name.vue' /* webpackChunkName: "pages/account/specialproducts/_name" */))
const _2ae2b494 = () => interopDefault(import('../pages/approve/gr/_id.vue' /* webpackChunkName: "pages/approve/gr/_id" */))
const _3774433e = () => interopDefault(import('../pages/approve/printing/_id.vue' /* webpackChunkName: "pages/approve/printing/_id" */))
const _1570fd82 = () => interopDefault(import('../pages/approve/validate/_id.vue' /* webpackChunkName: "pages/approve/validate/_id" */))
const _0bd10e4d = () => interopDefault(import('../pages/checkout/callback/_id.vue' /* webpackChunkName: "pages/checkout/callback/_id" */))
const _6b1b42d3 = () => interopDefault(import('../pages/checkout/complete/_orderId.vue' /* webpackChunkName: "pages/checkout/complete/_orderId" */))
const _8fccf550 = () => interopDefault(import('../pages/checkout/payment/_tempId.vue' /* webpackChunkName: "pages/checkout/payment/_tempId" */))
const _63774bac = () => interopDefault(import('../pages/checkout/shipping/_tempId.vue' /* webpackChunkName: "pages/checkout/shipping/_tempId" */))
const _52d04538 = () => interopDefault(import('../pages/company/gr/_id.vue' /* webpackChunkName: "pages/company/gr/_id" */))
const _5261fdcc = () => interopDefault(import('../pages/company/news/_id.vue' /* webpackChunkName: "pages/company/news/_id" */))
const _7f789ce4 = () => interopDefault(import('../pages/payment/123payment/_id.vue' /* webpackChunkName: "pages/payment/123payment/_id" */))
const _30ef0dbc = () => interopDefault(import('../pages/payment/re-payment/_id.vue' /* webpackChunkName: "pages/payment/re-payment/_id" */))
const _201d3f64 = () => interopDefault(import('../pages/activity/_url.vue' /* webpackChunkName: "pages/activity/_url" */))
const _9a5b6fea = () => interopDefault(import('../pages/addondeal/_id.vue' /* webpackChunkName: "pages/addondeal/_id" */))
const _92c21aa8 = () => interopDefault(import('../pages/approve/_id.vue' /* webpackChunkName: "pages/approve/_id" */))
const _ee7f63a4 = () => interopDefault(import('../pages/brand/_keyword.vue' /* webpackChunkName: "pages/brand/_keyword" */))
const _5d9080df = () => interopDefault(import('../pages/category/_department/index.vue' /* webpackChunkName: "pages/category/_department/index" */))
const _28a9de8e = () => interopDefault(import('../pages/coupon-item/_id.vue' /* webpackChunkName: "pages/coupon-item/_id" */))
const _6fe6fa4e = () => interopDefault(import('../pages/product/_id.vue' /* webpackChunkName: "pages/product/_id" */))
const _e3680f3a = () => interopDefault(import('../pages/promotions/_url.vue' /* webpackChunkName: "pages/promotions/_url" */))
const _f7c5898c = () => interopDefault(import('../pages/search-shop/_keyword.vue' /* webpackChunkName: "pages/search-shop/_keyword" */))
const _2d550d36 = () => interopDefault(import('../pages/search/_keyword.vue' /* webpackChunkName: "pages/search/_keyword" */))
const _ee0d716c = () => interopDefault(import('../pages/similar/_id.vue' /* webpackChunkName: "pages/similar/_id" */))
const _6aec0ada = () => interopDefault(import('../pages/special-deal/_url.vue' /* webpackChunkName: "pages/special-deal/_url" */))
const _f6beb34a = () => interopDefault(import('../pages/widget/_name.vue' /* webpackChunkName: "pages/widget/_name" */))
const _63405526 = () => interopDefault(import('../pages/category/_department/_category/index.vue' /* webpackChunkName: "pages/category/_department/_category/index" */))
const _3e0f51f9 = () => interopDefault(import('../pages/widget/_category/_id.vue' /* webpackChunkName: "pages/widget/_category/_id" */))
const _b67b1092 = () => interopDefault(import('../pages/category/_department/_category/_subcategory/index.vue' /* webpackChunkName: "pages/category/_department/_category/_subcategory/index" */))
const _6a5296bc = () => interopDefault(import('../pages/delivery-area/_id/_province/_zip.vue' /* webpackChunkName: "pages/delivery-area/_id/_province/_zip" */))
const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _408b2478 = () => interopDefault(import('../pages/_lang/index.vue' /* webpackChunkName: "pages/_lang/index" */))
const _192e6229 = () => interopDefault(import('../pages/_lang/403.vue' /* webpackChunkName: "pages/_lang/403" */))
const _3d942969 = () => interopDefault(import('../pages/_lang/apply-now.vue' /* webpackChunkName: "pages/_lang/apply-now" */))
const _0a232670 = () => interopDefault(import('../pages/_lang/backup-changePassword.vue' /* webpackChunkName: "pages/_lang/backup-changePassword" */))
const _01c9539b = () => interopDefault(import('../pages/_lang/backup-forgotpassword.vue' /* webpackChunkName: "pages/_lang/backup-forgotpassword" */))
const _8a3d22c8 = () => interopDefault(import('../pages/_lang/backup-login.vue' /* webpackChunkName: "pages/_lang/backup-login" */))
const _a983eba4 = () => interopDefault(import('../pages/_lang/backup-newpassword.vue' /* webpackChunkName: "pages/_lang/backup-newpassword" */))
const _3e17d4c0 = () => interopDefault(import('../pages/_lang/backup-register.vue' /* webpackChunkName: "pages/_lang/backup-register" */))
const _e8e11270 = () => interopDefault(import('../pages/_lang/biz/index.vue' /* webpackChunkName: "pages/_lang/biz/index" */))
const _150e594d = () => interopDefault(import('../pages/_lang/brands/index.vue' /* webpackChunkName: "pages/_lang/brands/index" */))
const _90a491be = () => interopDefault(import('../pages/_lang/cart/index.vue' /* webpackChunkName: "pages/_lang/cart/index" */))
const _17d23d2e = () => interopDefault(import('../pages/_lang/changePassword.vue' /* webpackChunkName: "pages/_lang/changePassword" */))
const _7f138bb2 = () => interopDefault(import('../pages/_lang/discount-coupon/index.vue' /* webpackChunkName: "pages/_lang/discount-coupon/index" */))
const _5568a195 = () => interopDefault(import('../pages/_lang/example-authen/index.vue' /* webpackChunkName: "pages/_lang/example-authen/index" */))
const _75a8fe7c = () => interopDefault(import('../pages/_lang/flash-sale-list/index.vue' /* webpackChunkName: "pages/_lang/flash-sale-list/index" */))
const _0a1c6f88 = () => interopDefault(import('../pages/_lang/forgotpassword.vue' /* webpackChunkName: "pages/_lang/forgotpassword" */))
const _ad3bde4a = () => interopDefault(import('../pages/_lang/login.vue' /* webpackChunkName: "pages/_lang/login" */))
const _6fe5c12d = () => interopDefault(import('../pages/_lang/newpassword.vue' /* webpackChunkName: "pages/_lang/newpassword" */))
const _773205a8 = () => interopDefault(import('../pages/_lang/notification/index.vue' /* webpackChunkName: "pages/_lang/notification/index" */))
const _7cb9a3c6 = () => interopDefault(import('../pages/_lang/otp.vue' /* webpackChunkName: "pages/_lang/otp" */))
const _58e8fbb1 = () => interopDefault(import('../pages/_lang/promotions/index.vue' /* webpackChunkName: "pages/_lang/promotions/index" */))
const _09589621 = () => interopDefault(import('../pages/_lang/register.vue' /* webpackChunkName: "pages/_lang/register" */))
const _7f01ca95 = () => interopDefault(import('../pages/_lang/register-complete.vue' /* webpackChunkName: "pages/_lang/register-complete" */))
const _90aca616 = () => interopDefault(import('../pages/_lang/request-credit.vue' /* webpackChunkName: "pages/_lang/request-credit" */))
const _0050f496 = () => interopDefault(import('../pages/_lang/rewards.vue' /* webpackChunkName: "pages/_lang/rewards" */))
const _235e1e62 = () => interopDefault(import('../pages/_lang/store-location/index.vue' /* webpackChunkName: "pages/_lang/store-location/index" */))
const _9fcf1312 = () => interopDefault(import('../pages/_lang/verify.vue' /* webpackChunkName: "pages/_lang/verify" */))
const _228c7fce = () => interopDefault(import('../pages/_lang/account/billing/index.vue' /* webpackChunkName: "pages/_lang/account/billing/index" */))
const _71699ea7 = () => interopDefault(import('../pages/_lang/account/dashboard/index.vue' /* webpackChunkName: "pages/_lang/account/dashboard/index" */))
const _5bc50d17 = () => interopDefault(import('../pages/_lang/account/delivery/index.vue' /* webpackChunkName: "pages/_lang/account/delivery/index" */))
const _2d32c8bf = () => interopDefault(import('../pages/_lang/account/favorite/index.vue' /* webpackChunkName: "pages/_lang/account/favorite/index" */))
const _3e95a582 = () => interopDefault(import('../pages/_lang/account/information/index.vue' /* webpackChunkName: "pages/_lang/account/information/index" */))
const _5b7f9dcf = () => interopDefault(import('../pages/_lang/account/select-company/index.vue' /* webpackChunkName: "pages/_lang/account/select-company/index" */))
const _514bcbf0 = () => interopDefault(import('../pages/_lang/account/specialproducts/index.vue' /* webpackChunkName: "pages/_lang/account/specialproducts/index" */))
const _effdffa4 = () => interopDefault(import('../pages/_lang/account/user/index.vue' /* webpackChunkName: "pages/_lang/account/user/index" */))
const _7ffc0122 = () => interopDefault(import('../pages/_lang/activity/store/index.vue' /* webpackChunkName: "pages/_lang/activity/store/index" */))
const _16da4bf4 = () => interopDefault(import('../pages/_lang/approver/b2b.vue' /* webpackChunkName: "pages/_lang/approver/b2b" */))
const _359720a4 = () => interopDefault(import('../pages/_lang/brands/index_backup.vue' /* webpackChunkName: "pages/_lang/brands/index_backup" */))
const _d985ee12 = () => interopDefault(import('../pages/_lang/company/dashboard/index.vue' /* webpackChunkName: "pages/_lang/company/dashboard/index" */))
const _0170760c = () => interopDefault(import('../pages/_lang/company/information.vue' /* webpackChunkName: "pages/_lang/company/information" */))
const _0c604e34 = () => interopDefault(import('../pages/_lang/company/news/index.vue' /* webpackChunkName: "pages/_lang/company/news/index" */))
const _848c7b92 = () => interopDefault(import('../pages/_lang/company/products/index.vue' /* webpackChunkName: "pages/_lang/company/products/index" */))
const _22a023b3 = () => interopDefault(import('../pages/_lang/company/setting/index.vue' /* webpackChunkName: "pages/_lang/company/setting/index" */))
const _5733dd45 = () => interopDefault(import('../pages/_lang/order/history.vue' /* webpackChunkName: "pages/_lang/order/history" */))
const _6e01bfa3 = () => interopDefault(import('../pages/_lang/payment/complete/index.vue' /* webpackChunkName: "pages/_lang/payment/complete/index" */))
const _1760aa70 = () => interopDefault(import('../pages/_lang/payment/fail/index.vue' /* webpackChunkName: "pages/_lang/payment/fail/index" */))
const _2e2bf19a = () => interopDefault(import('../pages/_lang/account/billing/create/index.vue' /* webpackChunkName: "pages/_lang/account/billing/create/index" */))
const _059ea5ca = () => interopDefault(import('../pages/_lang/account/delivery/create/index.vue' /* webpackChunkName: "pages/_lang/account/delivery/create/index" */))
const _a6aa88ac = () => interopDefault(import('../pages/_lang/account/information/backup-index.vue' /* webpackChunkName: "pages/_lang/account/information/backup-index" */))
const _457aaa35 = () => interopDefault(import('../pages/_lang/account/new-register/address.vue' /* webpackChunkName: "pages/_lang/account/new-register/address" */))
const _a062d732 = () => interopDefault(import('../pages/_lang/account/new-register/check-tax.vue' /* webpackChunkName: "pages/_lang/account/new-register/check-tax" */))
const _78534a5a = () => interopDefault(import('../pages/_lang/account/user/create/index.vue' /* webpackChunkName: "pages/_lang/account/user/create/index" */))
const _67df8e98 = () => interopDefault(import('../pages/_lang/company/news/create.vue' /* webpackChunkName: "pages/_lang/company/news/create" */))
const _17e7bc86 = () => interopDefault(import('../pages/_lang/company/printing/cart/index.vue' /* webpackChunkName: "pages/_lang/company/printing/cart/index" */))
const _100cb3ed = () => interopDefault(import('../pages/_lang/company/printing/history/index.vue' /* webpackChunkName: "pages/_lang/company/printing/history/index" */))
const _79ca7997 = () => interopDefault(import('../pages/_lang/company/printing/template/index.vue' /* webpackChunkName: "pages/_lang/company/printing/template/index" */))
const _2fa7fbe3 = () => interopDefault(import('../pages/_lang/company/report/budget/index.vue' /* webpackChunkName: "pages/_lang/company/report/budget/index" */))
const _0a1d5615 = () => interopDefault(import('../pages/_lang/company/report/budget-printing/index.vue' /* webpackChunkName: "pages/_lang/company/report/budget-printing/index" */))
const _4d6bdcc8 = () => interopDefault(import('../pages/_lang/company/report/category/index.vue' /* webpackChunkName: "pages/_lang/company/report/category/index" */))
const _5f295b89 = () => interopDefault(import('../pages/_lang/company/report/gr/index.vue' /* webpackChunkName: "pages/_lang/company/report/gr/index" */))
const _2d4da8c6 = () => interopDefault(import('../pages/_lang/company/report/order/index.vue' /* webpackChunkName: "pages/_lang/company/report/order/index" */))
const _e98f0edc = () => interopDefault(import('../pages/_lang/company/report/order-printing/index.vue' /* webpackChunkName: "pages/_lang/company/report/order-printing/index" */))
const _096e0a14 = () => interopDefault(import('../pages/_lang/company/setting/costcenter/index.vue' /* webpackChunkName: "pages/_lang/company/setting/costcenter/index" */))
const _7ff3ae1e = () => interopDefault(import('../pages/_lang/company/user/admin/index.vue' /* webpackChunkName: "pages/_lang/company/user/admin/index" */))
const _830f1dba = () => interopDefault(import('../pages/_lang/company/user/create/index.vue' /* webpackChunkName: "pages/_lang/company/user/create/index" */))
const _235fbe4c = () => interopDefault(import('../pages/_lang/company/user/manage/index.vue' /* webpackChunkName: "pages/_lang/company/user/manage/index" */))
const _1afc1363 = () => interopDefault(import('../pages/_lang/universal-link/qr/login/index.vue' /* webpackChunkName: "pages/_lang/universal-link/qr/login/index" */))
const _550a40d4 = () => interopDefault(import('../pages/_lang/account/user/create/backup-index.vue' /* webpackChunkName: "pages/_lang/account/user/create/backup-index" */))
const _7cab822d = () => interopDefault(import('../pages/_lang/company/setting/costcenter/create/index.vue' /* webpackChunkName: "pages/_lang/company/setting/costcenter/create/index" */))
const _2205b388 = () => interopDefault(import('../pages/_lang/company/user/manage/setting/create/_id.vue' /* webpackChunkName: "pages/_lang/company/user/manage/setting/create/_id" */))
const _13a1208a = () => interopDefault(import('../pages/_lang/company/user/manage/setting/edit/_id.vue' /* webpackChunkName: "pages/_lang/company/user/manage/setting/edit/_id" */))
const _6e39f632 = () => interopDefault(import('../pages/_lang/company/printing/template/create/_id.vue' /* webpackChunkName: "pages/_lang/company/printing/template/create/_id" */))
const _4bc8b863 = () => interopDefault(import('../pages/_lang/company/setting/costcenter/edit/_id.vue' /* webpackChunkName: "pages/_lang/company/setting/costcenter/edit/_id" */))
const _1e925cf5 = () => interopDefault(import('../pages/_lang/company/user/manage/setting/_id.vue' /* webpackChunkName: "pages/_lang/company/user/manage/setting/_id" */))
const _6b6eb069 = () => interopDefault(import('../pages/_lang/account/billing/edit/_id.vue' /* webpackChunkName: "pages/_lang/account/billing/edit/_id" */))
const _47703280 = () => interopDefault(import('../pages/_lang/account/delivery/edit/_id.vue' /* webpackChunkName: "pages/_lang/account/delivery/edit/_id" */))
const _485457ee = () => interopDefault(import('../pages/_lang/account/user/edit/_id.vue' /* webpackChunkName: "pages/_lang/account/user/edit/_id" */))
const _04386daa = () => interopDefault(import('../pages/_lang/approve/printing/validate/_id.vue' /* webpackChunkName: "pages/_lang/approve/printing/validate/_id" */))
const _5c68bf5e = () => interopDefault(import('../pages/_lang/company/news/edit/_id.vue' /* webpackChunkName: "pages/_lang/company/news/edit/_id" */))
const _077d2b7e = () => interopDefault(import('../pages/_lang/company/printing/complete/_id.vue' /* webpackChunkName: "pages/_lang/company/printing/complete/_id" */))
const _18bfa462 = () => interopDefault(import('../pages/_lang/company/printing/order/_id.vue' /* webpackChunkName: "pages/_lang/company/printing/order/_id" */))
const _359f1192 = () => interopDefault(import('../pages/_lang/company/products/create/_id.vue' /* webpackChunkName: "pages/_lang/company/products/create/_id" */))
const _f95c2d40 = () => interopDefault(import('../pages/_lang/company/products/edit/_id.vue' /* webpackChunkName: "pages/_lang/company/products/edit/_id" */))
const _88784c86 = () => interopDefault(import('../pages/_lang/company/report/budget-printing/_id.vue' /* webpackChunkName: "pages/_lang/company/report/budget-printing/_id" */))
const _07b99b0b = () => interopDefault(import('../pages/_lang/company/report/budget/_id.vue' /* webpackChunkName: "pages/_lang/company/report/budget/_id" */))
const _291f9259 = () => interopDefault(import('../pages/_lang/company/user/edit/_id.vue' /* webpackChunkName: "pages/_lang/company/user/edit/_id" */))
const _846dc4ee = () => interopDefault(import('../pages/_lang/account/order/_id.vue' /* webpackChunkName: "pages/_lang/account/order/_id" */))
const _64fb6c16 = () => interopDefault(import('../pages/_lang/approve/gr/_id.vue' /* webpackChunkName: "pages/_lang/approve/gr/_id" */))
const _1e5c5d80 = () => interopDefault(import('../pages/_lang/approve/printing/_id.vue' /* webpackChunkName: "pages/_lang/approve/printing/_id" */))
const _a2b5e17e = () => interopDefault(import('../pages/_lang/approve/validate/_id.vue' /* webpackChunkName: "pages/_lang/approve/validate/_id" */))
const _7e25408b = () => interopDefault(import('../pages/_lang/checkout/callback/_id.vue' /* webpackChunkName: "pages/_lang/checkout/callback/_id" */))
const _0962c755 = () => interopDefault(import('../pages/_lang/checkout/complete/_orderId.vue' /* webpackChunkName: "pages/_lang/checkout/complete/_orderId" */))
const _4c1c425a = () => interopDefault(import('../pages/_lang/checkout/payment/_tempId.vue' /* webpackChunkName: "pages/_lang/checkout/payment/_tempId" */))
const _8acd8530 = () => interopDefault(import('../pages/_lang/checkout/shipping/_tempId.vue' /* webpackChunkName: "pages/_lang/checkout/shipping/_tempId" */))
const _10b094e6 = () => interopDefault(import('../pages/_lang/company/gr/_id.vue' /* webpackChunkName: "pages/_lang/company/gr/_id" */))
const _692adcce = () => interopDefault(import('../pages/_lang/company/news/_id.vue' /* webpackChunkName: "pages/_lang/company/news/_id" */))
const _57aab266 = () => interopDefault(import('../pages/_lang/payment/123payment/_id.vue' /* webpackChunkName: "pages/_lang/payment/123payment/_id" */))
const _0921233e = () => interopDefault(import('../pages/_lang/payment/re-payment/_id.vue' /* webpackChunkName: "pages/_lang/payment/re-payment/_id" */))
const _2fa2e0bc = () => interopDefault(import('../pages/_lang/activity/_url.vue' /* webpackChunkName: "pages/_lang/activity/_url" */))
const _7ae39849 = () => interopDefault(import('../pages/_lang/addondeal/_id.vue' /* webpackChunkName: "pages/_lang/addondeal/_id" */))
const _6ea33a6a = () => interopDefault(import('../pages/_lang/approve/_id.vue' /* webpackChunkName: "pages/_lang/approve/_id" */))
const _7a4df4a0 = () => interopDefault(import('../pages/_lang/brand/_keyword.vue' /* webpackChunkName: "pages/_lang/brand/_keyword" */))
const _084ff53e = () => interopDefault(import('../pages/_lang/category/_department/index.vue' /* webpackChunkName: "pages/_lang/category/_department/index" */))
const _16ad6d12 = () => interopDefault(import('../pages/_lang/coupon-item/_id.vue' /* webpackChunkName: "pages/_lang/coupon-item/_id" */))
const _27eb420c = () => interopDefault(import('../pages/_lang/product/_id.vue' /* webpackChunkName: "pages/_lang/product/_id" */))
const _d16b9dbe = () => interopDefault(import('../pages/_lang/promotions/_url.vue' /* webpackChunkName: "pages/_lang/promotions/_url" */))
const _3d7ac93c = () => interopDefault(import('../pages/_lang/search-shop/_keyword.vue' /* webpackChunkName: "pages/_lang/search-shop/_keyword" */))
const _1b589bba = () => interopDefault(import('../pages/_lang/search/_keyword.vue' /* webpackChunkName: "pages/_lang/search/_keyword" */))
const _40fd8f08 = () => interopDefault(import('../pages/_lang/similar/_id.vue' /* webpackChunkName: "pages/_lang/similar/_id" */))
const _e646085e = () => interopDefault(import('../pages/_lang/special-deal/_url.vue' /* webpackChunkName: "pages/_lang/special-deal/_url" */))
const _65b55346 = () => interopDefault(import('../pages/_lang/widget/_name.vue' /* webpackChunkName: "pages/_lang/widget/_name" */))
const _08351f6f = () => interopDefault(import('../pages/_lang/category/_department/_category/index.vue' /* webpackChunkName: "pages/_lang/category/_department/_category/index" */))
const _1126400a = () => interopDefault(import('../pages/_lang/widget/_category/_id.vue' /* webpackChunkName: "pages/_lang/widget/_category/_id" */))
const _a5ddfe16 = () => interopDefault(import('../pages/_lang/category/_department/_category/_subcategory/index.vue' /* webpackChunkName: "pages/_lang/category/_department/_category/_subcategory/index" */))
const _ea5d9c84 = () => interopDefault(import('../pages/_lang/delivery-area/_id/_province/_zip.vue' /* webpackChunkName: "pages/_lang/delivery-area/_id/_province/_zip" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/403",
    component: _5581072a,
    name: "403"
  }, {
    path: "/apply-now",
    component: _2375ebaa,
    name: "apply-now"
  }, {
    path: "/biz",
    component: _886a3b72,
    name: "biz"
  }, {
    path: "/brands",
    component: _66ecad6a,
    name: "brands"
  }, {
    path: "/cart",
    component: _7453ad1c,
    name: "cart"
  }, {
    path: "/changePassword",
    component: _8c03ac32,
    name: "changePassword"
  }, {
    path: "/discount-coupon",
    component: _4e2207e9,
    name: "discount-coupon"
  }, {
    path: "/example-authen",
    component: _c7e9d8da,
    name: "example-authen"
  }, {
    path: "/flash-sale-list",
    component: _0354cc3e,
    name: "flash-sale-list"
  }, {
    path: "/forgotpassword",
    component: _7e4dde8c,
    name: "forgotpassword"
  }, {
    path: "/login",
    component: _b4d4bac6,
    name: "login"
  }, {
    path: "/newpassword",
    component: _903d0d22,
    name: "newpassword"
  }, {
    path: "/notification",
    component: _3c59d6aa,
    name: "notification"
  }, {
    path: "/otp",
    component: _7db4485f,
    name: "otp"
  }, {
    path: "/profile",
    component: _636a32a0,
    name: "profile"
  }, {
    path: "/promotions",
    component: _42201caf,
    name: "promotions"
  }, {
    path: "/redirect",
    component: _c601cc50,
    name: "redirect"
  }, {
    path: "/register",
    component: _02f0d5c2,
    name: "register"
  }, {
    path: "/register-complete",
    component: _86a26d52,
    name: "register-complete"
  }, {
    path: "/request-credit",
    component: _7d90f573,
    name: "request-credit"
  }, {
    path: "/rewards",
    component: _8431c450,
    name: "rewards"
  }, {
    path: "/store-location",
    component: _6a009060,
    name: "store-location"
  }, {
    path: "/verify",
    component: _3a571cf5,
    name: "verify"
  }, {
    path: "/account/billing",
    component: _9f8f64e0,
    name: "account-billing"
  }, {
    path: "/account/dashboard",
    component: _435903e9,
    name: "account-dashboard"
  }, {
    path: "/account/delivery",
    component: _f8da10d6,
    name: "account-delivery"
  }, {
    path: "/account/favorite",
    component: _5500b33d,
    name: "account-favorite"
  }, {
    path: "/account/information",
    component: _173f6bfe,
    name: "account-information"
  }, {
    path: "/account/select-company",
    component: _310731cd,
    name: "account-select-company"
  }, {
    path: "/account/user",
    component: _00184ca8,
    name: "account-user"
  }, {
    path: "/activity/store",
    component: _72c319c0,
    name: "activity-store"
  }, {
    path: "/approver/b2b",
    component: _d2a7c4f2,
    name: "approver-b2b"
  }, {
    path: "/company/dashboard",
    component: _652c6e39,
    name: "company-dashboard"
  }, {
    path: "/company/information",
    component: _f04e6764,
    name: "company-information"
  }, {
    path: "/company/news",
    component: _71c2b264,
    name: "company-news"
  }, {
    path: "/company/products",
    component: _34f0a696,
    name: "company-products"
  }, {
    path: "/company/setting",
    component: _9f681d16,
    name: "company-setting"
  }, {
    path: "/home/callback",
    component: _723b88a9,
    name: "home-callback"
  }, {
    path: "/order/history",
    component: _e1bae5f2,
    name: "order-history"
  }, {
    path: "/payment/complete",
    component: _d460abbe,
    name: "payment-complete"
  }, {
    path: "/payment/fail",
    component: _6c428446,
    name: "payment-fail"
  }, {
    path: "/account/billing/create",
    component: _831cc99e,
    name: "account-billing-create"
  }, {
    path: "/account/delivery/create",
    component: _3decdce8,
    name: "account-delivery-create"
  }, {
    path: "/account/new-register/address",
    component: _1b023e33,
    name: "account-new-register-address"
  }, {
    path: "/account/new-register/check-tax",
    component: _41c120e5,
    name: "account-new-register-check-tax"
  }, {
    path: "/account/user/create",
    component: _50fd10d6,
    name: "account-user-create"
  }, {
    path: "/company/news/create",
    component: _2370364c,
    name: "company-news-create"
  }, {
    path: "/company/printing/cart",
    component: _6d39d402,
    name: "company-printing-cart"
  }, {
    path: "/company/printing/history",
    component: _bc017f2a,
    name: "company-printing-history"
  }, {
    path: "/company/printing/template",
    component: _26297cd9,
    name: "company-printing-template"
  }, {
    path: "/company/report/budget",
    component: _04fef025,
    name: "company-report-budget"
  }, {
    path: "/company/report/budget-printing",
    component: _5f6fe7da,
    name: "company-report-budget-printing"
  }, {
    path: "/company/report/category",
    component: _96960544,
    name: "company-report-category"
  }, {
    path: "/company/report/gr",
    component: _3118c0cb,
    name: "company-report-gr"
  }, {
    path: "/company/report/order",
    component: _e1f3b778,
    name: "company-report-order"
  }, {
    path: "/company/report/order-printing",
    component: _1e000ad4,
    name: "company-report-order-printing"
  }, {
    path: "/company/setting/costcenter",
    component: _2e2121dc,
    name: "company-setting-costcenter"
  }, {
    path: "/company/user/admin",
    component: _281e1dc8,
    name: "company-user-admin"
  }, {
    path: "/company/user/create",
    component: _5bb8e436,
    name: "company-user-create"
  }, {
    path: "/company/user/manage",
    component: _370adb0e,
    name: "company-user-manage"
  }, {
    path: "/universal-link/qr/login",
    component: _133201b6,
    name: "universal-link-qr-login"
  }, {
    path: "/company/setting/costcenter/create",
    component: _60fc236f,
    name: "company-setting-costcenter-create"
  }, {
    path: "/company/user/manage/setting/create/:id?",
    component: _555b568c,
    name: "company-user-manage-setting-create-id"
  }, {
    path: "/company/user/manage/setting/edit/:id?",
    component: _4c6852f0,
    name: "company-user-manage-setting-edit-id"
  }, {
    path: "/company/printing/template/create/:id?",
    component: _3464ac30,
    name: "company-printing-template-create-id"
  }, {
    path: "/company/setting/costcenter/edit/:id?",
    component: _42df6ab6,
    name: "company-setting-costcenter-edit-id"
  }, {
    path: "/company/user/manage/setting/:id?",
    component: _6a1d3f92,
    name: "company-user-manage-setting-id"
  }, {
    path: "/account/billing/edit/:id?",
    component: _51281932,
    name: "account-billing-edit-id"
  }, {
    path: "/account/delivery/edit/:id?",
    component: _5b1b4f42,
    name: "account-delivery-edit-id"
  }, {
    path: "/account/user/edit/:id?",
    component: _6981a1cb,
    name: "account-user-edit-id"
  }, {
    path: "/approve/printing/validate/:id?",
    component: _4d629626,
    name: "approve-printing-validate-id"
  }, {
    path: "/company/news/edit/:id?",
    component: _5f776e13,
    name: "company-news-edit-id"
  }, {
    path: "/company/printing/complete/:id?",
    component: _3a2fd180,
    name: "company-printing-complete-id"
  }, {
    path: "/company/printing/order/:id?",
    component: _5558a94d,
    name: "company-printing-order-id"
  }, {
    path: "/company/products/create/:id?",
    component: _0af605d4,
    name: "company-products-create-id"
  }, {
    path: "/company/products/edit/:id?",
    component: _d205f3bc,
    name: "company-products-edit-id"
  }, {
    path: "/company/report/budget-printing/:id",
    component: _7f00118a,
    name: "company-report-budget-printing-id"
  }, {
    path: "/company/report/budget/:id",
    component: _1b64b7cd,
    name: "company-report-budget-id"
  }, {
    path: "/company/user/edit/:id?",
    component: _92693fca,
    name: "company-user-edit-id"
  }, {
    path: "/account/order/:id?",
    component: _0913c76a,
    name: "account-order-id"
  }, {
    path: "/account/specialproducts/:name?",
    component: _601a936c,
    name: "account-specialproducts-name"
  }, {
    path: "/approve/gr/:id?",
    component: _2ae2b494,
    name: "approve-gr-id"
  }, {
    path: "/approve/printing/:id?",
    component: _3774433e,
    name: "approve-printing-id"
  }, {
    path: "/approve/validate/:id?",
    component: _1570fd82,
    name: "approve-validate-id"
  }, {
    path: "/checkout/callback/:id?",
    component: _0bd10e4d,
    name: "checkout-callback-id"
  }, {
    path: "/checkout/complete/:orderId?",
    component: _6b1b42d3,
    name: "checkout-complete-orderId"
  }, {
    path: "/checkout/payment/:tempId?",
    component: _8fccf550,
    name: "checkout-payment-tempId"
  }, {
    path: "/checkout/shipping/:tempId?",
    component: _63774bac,
    name: "checkout-shipping-tempId"
  }, {
    path: "/company/gr/:id?",
    component: _52d04538,
    name: "company-gr-id"
  }, {
    path: "/company/news/:id",
    component: _5261fdcc,
    name: "company-news-id"
  }, {
    path: "/payment/123payment/:id?",
    component: _7f789ce4,
    name: "payment-123payment-id"
  }, {
    path: "/payment/re-payment/:id?",
    component: _30ef0dbc,
    name: "payment-re-payment-id"
  }, {
    path: "/activity/:url?",
    component: _201d3f64,
    name: "activity-url"
  }, {
    path: "/addondeal/:id?",
    component: _9a5b6fea,
    name: "addondeal-id"
  }, {
    path: "/approve/:id?",
    component: _92c21aa8,
    name: "approve-id"
  }, {
    path: "/brand/:keyword?",
    component: _ee7f63a4,
    name: "brand-keyword"
  }, {
    path: "/category/:department",
    component: _5d9080df,
    name: "category-department"
  }, {
    path: "/coupon-item/:id?",
    component: _28a9de8e,
    name: "coupon-item-id"
  }, {
    path: "/product/:id?",
    component: _6fe6fa4e,
    name: "product-id"
  }, {
    path: "/promotions/:url",
    component: _e3680f3a,
    name: "promotions-url"
  }, {
    path: "/search-shop/:keyword?",
    component: _f7c5898c,
    name: "search-shop-keyword"
  }, {
    path: "/search/:keyword?",
    component: _2d550d36,
    name: "search-keyword"
  }, {
    path: "/similar/:id?",
    component: _ee0d716c,
    name: "similar-id"
  }, {
    path: "/special-deal/:url?",
    component: _6aec0ada,
    name: "special-deal-url"
  }, {
    path: "/widget/:name?",
    component: _f6beb34a,
    name: "widget-name"
  }, {
    path: "/category/:department?/:category",
    component: _63405526,
    name: "category-department-category"
  }, {
    path: "/widget/:category?/:id?",
    component: _3e0f51f9,
    name: "widget-category-id"
  }, {
    path: "/category/:department?/:category/:subcategory",
    component: _b67b1092,
    name: "category-department-category-subcategory"
  }, {
    path: "/delivery-area/:id?/:province?/:zip?",
    component: _6a5296bc,
    name: "delivery-area-id-province-zip"
  }, {
    path: "/",
    component: _482400f4,
    name: "index"
  }, {
    path: "/:lang",
    component: _408b2478,
    name: "lang"
  }, {
    path: "/:lang/403",
    component: _192e6229,
    name: "lang-403"
  }, {
    path: "/:lang/apply-now",
    component: _3d942969,
    name: "lang-apply-now"
  }, {
    path: "/:lang/backup-changePassword",
    component: _0a232670,
    name: "lang-backup-changePassword"
  }, {
    path: "/:lang/backup-forgotpassword",
    component: _01c9539b,
    name: "lang-backup-forgotpassword"
  }, {
    path: "/:lang/backup-login",
    component: _8a3d22c8,
    name: "lang-backup-login"
  }, {
    path: "/:lang/backup-newpassword",
    component: _a983eba4,
    name: "lang-backup-newpassword"
  }, {
    path: "/:lang/backup-register",
    component: _3e17d4c0,
    name: "lang-backup-register"
  }, {
    path: "/:lang/biz",
    component: _e8e11270,
    name: "lang-biz"
  }, {
    path: "/:lang/brands",
    component: _150e594d,
    name: "lang-brands"
  }, {
    path: "/:lang/cart",
    component: _90a491be,
    name: "lang-cart"
  }, {
    path: "/:lang/changePassword",
    component: _17d23d2e,
    name: "lang-changePassword"
  }, {
    path: "/:lang/discount-coupon",
    component: _7f138bb2,
    name: "lang-discount-coupon"
  }, {
    path: "/:lang/example-authen",
    component: _5568a195,
    name: "lang-example-authen"
  }, {
    path: "/:lang/flash-sale-list",
    component: _75a8fe7c,
    name: "lang-flash-sale-list"
  }, {
    path: "/:lang/forgotpassword",
    component: _0a1c6f88,
    name: "lang-forgotpassword"
  }, {
    path: "/:lang/login",
    component: _ad3bde4a,
    name: "lang-login"
  }, {
    path: "/:lang/newpassword",
    component: _6fe5c12d,
    name: "lang-newpassword"
  }, {
    path: "/:lang/notification",
    component: _773205a8,
    name: "lang-notification"
  }, {
    path: "/:lang/otp",
    component: _7cb9a3c6,
    name: "lang-otp"
  }, {
    path: "/:lang/promotions",
    component: _58e8fbb1,
    name: "lang-promotions"
  }, {
    path: "/:lang/register",
    component: _09589621,
    name: "lang-register"
  }, {
    path: "/:lang/register-complete",
    component: _7f01ca95,
    name: "lang-register-complete"
  }, {
    path: "/:lang/request-credit",
    component: _90aca616,
    name: "lang-request-credit"
  }, {
    path: "/:lang/rewards",
    component: _0050f496,
    name: "lang-rewards"
  }, {
    path: "/:lang/store-location",
    component: _235e1e62,
    name: "lang-store-location"
  }, {
    path: "/:lang/verify",
    component: _9fcf1312,
    name: "lang-verify"
  }, {
    path: "/:lang/account/billing",
    component: _228c7fce,
    name: "lang-account-billing"
  }, {
    path: "/:lang/account/dashboard",
    component: _71699ea7,
    name: "lang-account-dashboard"
  }, {
    path: "/:lang/account/delivery",
    component: _5bc50d17,
    name: "lang-account-delivery"
  }, {
    path: "/:lang/account/favorite",
    component: _2d32c8bf,
    name: "lang-account-favorite"
  }, {
    path: "/:lang/account/information",
    component: _3e95a582,
    name: "lang-account-information"
  }, {
    path: "/:lang/account/select-company",
    component: _5b7f9dcf,
    name: "lang-account-select-company"
  }, {
    path: "/:lang/account/specialproducts",
    component: _514bcbf0,
    name: "lang-account-specialproducts"
  }, {
    path: "/:lang/account/user",
    component: _effdffa4,
    name: "lang-account-user"
  }, {
    path: "/:lang/activity/store",
    component: _7ffc0122,
    name: "lang-activity-store"
  }, {
    path: "/:lang/approver/b2b",
    component: _16da4bf4,
    name: "lang-approver-b2b"
  }, {
    path: "/:lang/brands/index_backup",
    component: _359720a4,
    name: "lang-brands-index_backup"
  }, {
    path: "/:lang/company/dashboard",
    component: _d985ee12,
    name: "lang-company-dashboard"
  }, {
    path: "/:lang/company/information",
    component: _0170760c,
    name: "lang-company-information"
  }, {
    path: "/:lang/company/news",
    component: _0c604e34,
    name: "lang-company-news"
  }, {
    path: "/:lang/company/products",
    component: _848c7b92,
    name: "lang-company-products"
  }, {
    path: "/:lang/company/setting",
    component: _22a023b3,
    name: "lang-company-setting"
  }, {
    path: "/:lang/order/history",
    component: _5733dd45,
    name: "lang-order-history"
  }, {
    path: "/:lang/payment/complete",
    component: _6e01bfa3,
    name: "lang-payment-complete"
  }, {
    path: "/:lang/payment/fail",
    component: _1760aa70,
    name: "lang-payment-fail"
  }, {
    path: "/:lang/account/billing/create",
    component: _2e2bf19a,
    name: "lang-account-billing-create"
  }, {
    path: "/:lang/account/delivery/create",
    component: _059ea5ca,
    name: "lang-account-delivery-create"
  }, {
    path: "/:lang/account/information/backup-index",
    component: _a6aa88ac,
    name: "lang-account-information-backup"
  }, {
    path: "/:lang/account/new-register/address",
    component: _457aaa35,
    name: "lang-account-new-register-address"
  }, {
    path: "/:lang/account/new-register/check-tax",
    component: _a062d732,
    name: "lang-account-new-register-check-tax"
  }, {
    path: "/:lang/account/user/create",
    component: _78534a5a,
    name: "lang-account-user-create"
  }, {
    path: "/:lang/company/news/create",
    component: _67df8e98,
    name: "lang-company-news-create"
  }, {
    path: "/:lang/company/printing/cart",
    component: _17e7bc86,
    name: "lang-company-printing-cart"
  }, {
    path: "/:lang/company/printing/history",
    component: _100cb3ed,
    name: "lang-company-printing-history"
  }, {
    path: "/:lang/company/printing/template",
    component: _79ca7997,
    name: "lang-company-printing-template"
  }, {
    path: "/:lang/company/report/budget",
    component: _2fa7fbe3,
    name: "lang-company-report-budget"
  }, {
    path: "/:lang/company/report/budget-printing",
    component: _0a1d5615,
    name: "lang-company-report-budget-printing"
  }, {
    path: "/:lang/company/report/category",
    component: _4d6bdcc8,
    name: "lang-company-report-category"
  }, {
    path: "/:lang/company/report/gr",
    component: _5f295b89,
    name: "lang-company-report-gr"
  }, {
    path: "/:lang/company/report/order",
    component: _2d4da8c6,
    name: "lang-company-report-order"
  }, {
    path: "/:lang/company/report/order-printing",
    component: _e98f0edc,
    name: "lang-company-report-order-printing"
  }, {
    path: "/:lang/company/setting/costcenter",
    component: _096e0a14,
    name: "lang-company-setting-costcenter"
  }, {
    path: "/:lang/company/user/admin",
    component: _7ff3ae1e,
    name: "lang-company-user-admin"
  }, {
    path: "/:lang/company/user/create",
    component: _830f1dba,
    name: "lang-company-user-create"
  }, {
    path: "/:lang/company/user/manage",
    component: _235fbe4c,
    name: "lang-company-user-manage"
  }, {
    path: "/:lang/universal-link/qr/login",
    component: _1afc1363,
    name: "lang-universal-link-qr-login"
  }, {
    path: "/:lang/account/user/create/backup-index",
    component: _550a40d4,
    name: "lang-account-user-create-backup"
  }, {
    path: "/:lang/company/setting/costcenter/create",
    component: _7cab822d,
    name: "lang-company-setting-costcenter-create"
  }, {
    path: "/:lang/company/user/manage/setting/create/:id?",
    component: _2205b388,
    name: "lang-company-user-manage-setting-create-id"
  }, {
    path: "/:lang/company/user/manage/setting/edit/:id?",
    component: _13a1208a,
    name: "lang-company-user-manage-setting-edit-id"
  }, {
    path: "/:lang/company/printing/template/create/:id?",
    component: _6e39f632,
    name: "lang-company-printing-template-create-id"
  }, {
    path: "/:lang/company/setting/costcenter/edit/:id?",
    component: _4bc8b863,
    name: "lang-company-setting-costcenter-edit-id"
  }, {
    path: "/:lang/company/user/manage/setting/:id?",
    component: _1e925cf5,
    name: "lang-company-user-manage-setting-id"
  }, {
    path: "/:lang/account/billing/edit/:id?",
    component: _6b6eb069,
    name: "lang-account-billing-edit-id"
  }, {
    path: "/:lang/account/delivery/edit/:id?",
    component: _47703280,
    name: "lang-account-delivery-edit-id"
  }, {
    path: "/:lang/account/user/edit/:id?",
    component: _485457ee,
    name: "lang-account-user-edit-id"
  }, {
    path: "/:lang/approve/printing/validate/:id?",
    component: _04386daa,
    name: "lang-approve-printing-validate-id"
  }, {
    path: "/:lang/company/news/edit/:id?",
    component: _5c68bf5e,
    name: "lang-company-news-edit-id"
  }, {
    path: "/:lang/company/printing/complete/:id?",
    component: _077d2b7e,
    name: "lang-company-printing-complete-id"
  }, {
    path: "/:lang/company/printing/order/:id?",
    component: _18bfa462,
    name: "lang-company-printing-order-id"
  }, {
    path: "/:lang/company/products/create/:id?",
    component: _359f1192,
    name: "lang-company-products-create-id"
  }, {
    path: "/:lang/company/products/edit/:id?",
    component: _f95c2d40,
    name: "lang-company-products-edit-id"
  }, {
    path: "/:lang/company/report/budget-printing/:id",
    component: _88784c86,
    name: "lang-company-report-budget-printing-id"
  }, {
    path: "/:lang/company/report/budget/:id",
    component: _07b99b0b,
    name: "lang-company-report-budget-id"
  }, {
    path: "/:lang/company/user/edit/:id?",
    component: _291f9259,
    name: "lang-company-user-edit-id"
  }, {
    path: "/:lang/account/order/:id?",
    component: _846dc4ee,
    name: "lang-account-order-id"
  }, {
    path: "/:lang/approve/gr/:id?",
    component: _64fb6c16,
    name: "lang-approve-gr-id"
  }, {
    path: "/:lang/approve/printing/:id?",
    component: _1e5c5d80,
    name: "lang-approve-printing-id"
  }, {
    path: "/:lang/approve/validate/:id?",
    component: _a2b5e17e,
    name: "lang-approve-validate-id"
  }, {
    path: "/:lang/checkout/callback/:id?",
    component: _7e25408b,
    name: "lang-checkout-callback-id"
  }, {
    path: "/:lang/checkout/complete/:orderId?",
    component: _0962c755,
    name: "lang-checkout-complete-orderId"
  }, {
    path: "/:lang/checkout/payment/:tempId?",
    component: _4c1c425a,
    name: "lang-checkout-payment-tempId"
  }, {
    path: "/:lang/checkout/shipping/:tempId?",
    component: _8acd8530,
    name: "lang-checkout-shipping-tempId"
  }, {
    path: "/:lang/company/gr/:id?",
    component: _10b094e6,
    name: "lang-company-gr-id"
  }, {
    path: "/:lang/company/news/:id",
    component: _692adcce,
    name: "lang-company-news-id"
  }, {
    path: "/:lang/payment/123payment/:id?",
    component: _57aab266,
    name: "lang-payment-123payment-id"
  }, {
    path: "/:lang/payment/re-payment/:id?",
    component: _0921233e,
    name: "lang-payment-re-payment-id"
  }, {
    path: "/:lang/activity/:url?",
    component: _2fa2e0bc,
    name: "lang-activity-url"
  }, {
    path: "/:lang/addondeal/:id?",
    component: _7ae39849,
    name: "lang-addondeal-id"
  }, {
    path: "/:lang/approve/:id?",
    component: _6ea33a6a,
    name: "lang-approve-id"
  }, {
    path: "/:lang/brand/:keyword?",
    component: _7a4df4a0,
    name: "lang-brand-keyword"
  }, {
    path: "/:lang/category/:department",
    component: _084ff53e,
    name: "lang-category-department"
  }, {
    path: "/:lang/coupon-item/:id?",
    component: _16ad6d12,
    name: "lang-coupon-item-id"
  }, {
    path: "/:lang/product/:id?",
    component: _27eb420c,
    name: "lang-product-id"
  }, {
    path: "/:lang/promotions/:url",
    component: _d16b9dbe,
    name: "lang-promotions-url"
  }, {
    path: "/:lang/search-shop/:keyword?",
    component: _3d7ac93c,
    name: "lang-search-shop-keyword"
  }, {
    path: "/:lang/search/:keyword?",
    component: _1b589bba,
    name: "lang-search-keyword"
  }, {
    path: "/:lang/similar/:id?",
    component: _40fd8f08,
    name: "lang-similar-id"
  }, {
    path: "/:lang/special-deal/:url?",
    component: _e646085e,
    name: "lang-special-deal-url"
  }, {
    path: "/:lang/widget/:name?",
    component: _65b55346,
    name: "lang-widget-name"
  }, {
    path: "/:lang/category/:department?/:category",
    component: _08351f6f,
    name: "lang-category-department-category"
  }, {
    path: "/:lang/widget/:category?/:id?",
    component: _1126400a,
    name: "lang-widget-category-id"
  }, {
    path: "/:lang/category/:department?/:category/:subcategory",
    component: _a5ddfe16,
    name: "lang-category-department-category-subcategory"
  }, {
    path: "/:lang/delivery-area/:id?/:province?/:zip?",
    component: _ea5d9c84,
    name: "lang-delivery-area-id-province-zip"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
