export default function ({ store, redirect, req, app, route, error }) {
  // const userIP = req.headers['x-forwarded-for'] || req.connection.remoteAddress
  // req.userIP = userIP
  // console.log('ip', app.context.req.userIP)
  let exceptRoute = [
    'request-credit',
    'register',
    'lang-register',
    'apply-now',
    'lang-apply-now',
    'register-complete',
    'lang-register-complete',
    'biz',
    'lang-biz',
    'forgotpassword',
    'lang-forgotpassword',
    'newpassword',
    'lang-newpassword',
    'changePassword',
    'lang-changePassword',
    'approve-id',
    'lang-approve-id',
    'checkout-callback-id',
    'lang-checkout-callback-id',
    'login',
    'lang-login',
    'approve-gr-id',
    'lang-approve-gr-id',
    'approve-printing-id',
    'lang-approve-printing-id',
    'verify',
    'lang-verify',
    'approve-validate-id',
    'lang-approve-validate-id',
    'approve-printing-validate-id',
    'lang-approve-printing-validate-id',
    'otp',
    'lang-otp',
  ]

  let guestRoute = [
    'lang',
    'index',
    'lang-index',
    'search-keyword',
    'lang-search-keyword',
    'search-shop-keyword',
    'lang-search-shop-keyword',
    'widget-name',
    'lang-widget-name',
    'promotions',
    'lang-promotions',
    'brands',
    'lang-brands',
    'category-department',
    'lang-category-department',
    'category-department-category',
    'lang-category-department-category',
    'category-department-category-subcategory',
    'lang-category-department-category-subcategory',
    'product-id',
    'lang-product-id',
    'similar-id',
    'lang-similar-id',
    'all',
    'lang-all',
    'promotions-url',
    'lang-promotions-url',
    'activity-url',
    'lang-activity-url',
    'activity-name',
    'lang-activity-name',
    'brand-keyword',
    'lang-brand-keyword',
    'widget-category-id',
    'lang-widget-category-id',
    'flash-sale-list',
    'lang-flash-sale-list',
    'addondeal-id',
    'lang-addondeal-id',
    'rewards',
    'lang-rewards',
    'coupon-item-id',
    'lang-coupon-item-id',
    'universal-link-qr-login',
    'store-location',
    'lang-store-location',
    'special-deal-url',
    'lang-special-deal-url',
    'delivery-area-id-province-zip',
    'lang-delivery-area-id-province-zip',
    'discount-coupon',
    'lang-discount-coupon',
    '403',
    'lang-403',
  ]

  let authenRedirect = [
    'register',
    'lang-register',
    'forgotpassword',
    'lang-forgotpassword',
    'newpassword',
    'lang-newpassword',
    // 'changePassword',
    // 'lang-changePassword',
  ]

  let redirectHomeRoute = ['login', 'lang-login']
  if (process.server) {
    console.log('server side', route.name)
    const cookieparser = require('cookieparser')
    if (
      redirectHomeRoute.includes(route.name) &&
      store.state.authen.isAuthenticated &&
      !store.getters['authen/isGuest']
    )
      redirect(app.i18n.locale === 'th' ? '/' : '/en')

    if (!exceptRoute.includes(route.name)) {
      if (
        req !== undefined &&
        req !== null &&
        req.headers !== undefined &&
        req.headers !== null &&
        req.headers.cookie !== undefined &&
        req.headers.cookie !== null &&
        req.headers.cookie
      ) {
        cookieparser.maxLength = 16384
        const parsed = cookieparser.parse(req.headers.cookie)
        // console.log('parsed', parsed)
        if (parsed.accessToken !== undefined && parsed.accessToken !== '') {
          store.dispatch('authen/isAuthenticatedByCookie', parsed.accessToken)
          if (
            !store.state.authen.isAuthenticated ||
            (store.state.authen.isAuthenticated &&
              store.getters['authen/isGuest'])
          ) {
            return checkIsGuestAndRedirect()
          }
        } else {
          return checkIsGuestAndRedirect()
        }
      } else {
        return checkIsGuestAndRedirect()
      }
    } else {
      if (
        authenRedirect.includes(route.name) &&
        store.state.authen.isAuthenticated &&
        !store.getters['authen/isGuest']
      ) {
        if (
          store.getters['user/profileType'] !== 'eprocurement' &&
          (route.name !== 'changePassword' ||
            route.name !== 'lang-changePassword')
        )
          redirect(app.i18n.locale === 'th' ? '/' : '/en')
      }
    }
  } else {
    console.log('client side', route.name)
    if (
      redirectHomeRoute.includes(route.name) &&
      store.state.authen.isAuthenticated &&
      !store.getters['authen/isGuest']
    )
      redirect(app.i18n.locale === 'th' ? '/' : '/en')
    if (!exceptRoute.includes(route.name)) {
      store.dispatch('authen/isAuthenticated')
      if (
        !store.state.authen.isAuthenticated ||
        (store.state.authen.isAuthenticated && store.getters['authen/isGuest'])
      )
        return checkIsGuestAndRedirect()
    } else {
      if (
        authenRedirect.includes(route.name) &&
        store.state.authen.isAuthenticated &&
        !store.getters['authen/isGuest']
      ) {
        if (
          store.getters['user/profileType'] !== 'eprocurement' &&
          (route.name !== 'changePassword' ||
            route.name !== 'lang-changePassword')
        )
          redirect(app.i18n.locale === 'th' ? '/' : '/en')
      }
    }
  }

  function checkIsGuestAndRedirect() {
    console.log('checkIsGuestAndRedirect', route.name)
    if (guestRoute.includes(route.name)) {
      if (!store.getters['authen/isGuest']) {
        // console.log('guestRoute: ', process.env.NUXT_ENV_GUEST_TOKEN)
        let data = {
          accessToken: process.env.NUXT_ENV_GUEST_TOKEN,
          isVerify: true,
          openId: '',
          refreshToken: '',
          secretKey: '',
          user: null,
        }
        store.dispatch('authen/setAuthToken', data)

        let profile = {
          payload: {
            id: '',
            // "offline_id": "",
            status: 'Active',
            // "segment": "",
            // "is_activate": false,
            // "is_user_active": true,
            account_channel: 'standard',
            // "name": {
            //     "th": "",
            //     "en": ""
            // },
            last_active: new Date().toISOString(),
          },
          profileType: 'standard',
        }
        store.dispatch('user/setUserProfile', profile)
      }
      // saveRouteHistory
      console.log('route', route.fullPath)
      store.dispatch('saveRouteHistory', route.fullPath)
    } else {
      console.log('redirect login', route.name)
      store.dispatch('authen/signout')
      store.dispatch('user/removeUserProfile')
      store.dispatch('saveRouteHistory', '')
      // redirect(app.i18n.locale === 'th' ? '/login' : '/en/login')
      if (route.name) {
        redirect(app.i18n.locale === 'th' ? '/login' : '/en/login')
      } else {
        error({ statusCode: 404, message: 'Page not found' })
      }
    }
  }
}
