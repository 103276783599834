export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const CartItemsEmpty = () => import('../../components/cart/cart-items-empty.vue' /* webpackChunkName: "components/cart-items-empty" */).then(c => wrapFunctional(c.default || c))
export const CartItems = () => import('../../components/cart/cart-items.vue' /* webpackChunkName: "components/cart-items" */).then(c => wrapFunctional(c.default || c))
export const ApproverDropdown = () => import('../../components/checkout/approver-dropdown.vue' /* webpackChunkName: "components/approver-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ApproverListGr = () => import('../../components/checkout/approver-list-gr.vue' /* webpackChunkName: "components/approver-list-gr" */).then(c => wrapFunctional(c.default || c))
export const ApproverList = () => import('../../components/checkout/approver-list.vue' /* webpackChunkName: "components/approver-list" */).then(c => wrapFunctional(c.default || c))
export const DeliveryItems = () => import('../../components/checkout/delivery-items.vue' /* webpackChunkName: "components/delivery-items" */).then(c => wrapFunctional(c.default || c))
export const SummaryComponent = () => import('../../components/checkout/summary-component.vue' /* webpackChunkName: "components/summary-component" */).then(c => wrapFunctional(c.default || c))
export const SummaryGrComponent = () => import('../../components/checkout/summary-gr-component.vue' /* webpackChunkName: "components/summary-gr-component" */).then(c => wrapFunctional(c.default || c))
export const MainCategoryList = () => import('../../components/category/main-category-list.vue' /* webpackChunkName: "components/main-category-list" */).then(c => wrapFunctional(c.default || c))
export const CatalogItems = () => import('../../components/catalog/catalog-items.vue' /* webpackChunkName: "components/catalog-items" */).then(c => wrapFunctional(c.default || c))
export const TableCostcenter = () => import('../../components/company/table-costcenter.vue' /* webpackChunkName: "components/table-costcenter" */).then(c => wrapFunctional(c.default || c))
export const TableManageProduct = () => import('../../components/company/table-manage-product.vue' /* webpackChunkName: "components/table-manage-product" */).then(c => wrapFunctional(c.default || c))
export const TableManageRole = () => import('../../components/company/table-manage-role.vue' /* webpackChunkName: "components/table-manage-role" */).then(c => wrapFunctional(c.default || c))
export const TableManageUser = () => import('../../components/company/table-manage-user.vue' /* webpackChunkName: "components/table-manage-user" */).then(c => wrapFunctional(c.default || c))
export const TableReportBudgetLog = () => import('../../components/company/table-report-budget-log.vue' /* webpackChunkName: "components/table-report-budget-log" */).then(c => wrapFunctional(c.default || c))
export const TableReportBudget = () => import('../../components/company/table-report-budget.vue' /* webpackChunkName: "components/table-report-budget" */).then(c => wrapFunctional(c.default || c))
export const TableReportCategory = () => import('../../components/company/table-report-category.vue' /* webpackChunkName: "components/table-report-category" */).then(c => wrapFunctional(c.default || c))
export const TableReportGr = () => import('../../components/company/table-report-gr.vue' /* webpackChunkName: "components/table-report-gr" */).then(c => wrapFunctional(c.default || c))
export const TableReportOrder = () => import('../../components/company/table-report-order.vue' /* webpackChunkName: "components/table-report-order" */).then(c => wrapFunctional(c.default || c))
export const ModalShipping = () => import('../../components/delivery-charge/modalShipping.vue' /* webpackChunkName: "components/modal-shipping" */).then(c => wrapFunctional(c.default || c))
export const ModalShippingItems = () => import('../../components/delivery-charge/modalShippingItems.vue' /* webpackChunkName: "components/modal-shipping-items" */).then(c => wrapFunctional(c.default || c))
export const Countdown = () => import('../../components/flashsale/countdown.vue' /* webpackChunkName: "components/countdown" */).then(c => wrapFunctional(c.default || c))
export const MediaItems = () => import('../../components/installment/media-items.vue' /* webpackChunkName: "components/media-items" */).then(c => wrapFunctional(c.default || c))
export const RadioBanks = () => import('../../components/installment/radio-banks.vue' /* webpackChunkName: "components/radio-banks" */).then(c => wrapFunctional(c.default || c))
export const TooltipDetail = () => import('../../components/installment/tooltip-detail.vue' /* webpackChunkName: "components/tooltip-detail" */).then(c => wrapFunctional(c.default || c))
export const AddToCart = () => import('../../components/modal/addToCart.vue' /* webpackChunkName: "components/add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const AddonModal = () => import('../../components/modal/addonModal.vue' /* webpackChunkName: "components/addon-modal" */).then(c => wrapFunctional(c.default || c))
export const CheckoutWarning = () => import('../../components/modal/checkout-warning.vue' /* webpackChunkName: "components/checkout-warning" */).then(c => wrapFunctional(c.default || c))
export const ConfirmApproveOrder = () => import('../../components/modal/confirmApproveOrder.vue' /* webpackChunkName: "components/confirm-approve-order" */).then(c => wrapFunctional(c.default || c))
export const ConfirmGoodsRecieve = () => import('../../components/modal/confirmGoodsRecieve.vue' /* webpackChunkName: "components/confirm-goods-recieve" */).then(c => wrapFunctional(c.default || c))
export const ConfirmInformationModal = () => import('../../components/modal/confirmInformationModal.vue' /* webpackChunkName: "components/confirm-information-modal" */).then(c => wrapFunctional(c.default || c))
export const ConfirmModal = () => import('../../components/modal/confirmModal.vue' /* webpackChunkName: "components/confirm-modal" */).then(c => wrapFunctional(c.default || c))
export const ConfirmRemoveApprover = () => import('../../components/modal/confirmRemoveApprover.vue' /* webpackChunkName: "components/confirm-remove-approver" */).then(c => wrapFunctional(c.default || c))
export const ConfirmRemoveCostCenter = () => import('../../components/modal/confirmRemoveCostCenter.vue' /* webpackChunkName: "components/confirm-remove-cost-center" */).then(c => wrapFunctional(c.default || c))
export const ConfirmRemoveDepartment = () => import('../../components/modal/confirmRemoveDepartment.vue' /* webpackChunkName: "components/confirm-remove-department" */).then(c => wrapFunctional(c.default || c))
export const ConfirmRemoveManageProduct = () => import('../../components/modal/confirmRemoveManageProduct.vue' /* webpackChunkName: "components/confirm-remove-manage-product" */).then(c => wrapFunctional(c.default || c))
export const ModalOnboarding = () => import('../../components/modal/modalOnboarding.vue' /* webpackChunkName: "components/modal-onboarding" */).then(c => wrapFunctional(c.default || c))
export const ModalWarning = () => import('../../components/modal/modalWarning.vue' /* webpackChunkName: "components/modal-warning" */).then(c => wrapFunctional(c.default || c))
export const ModalWarningRePurchase = () => import('../../components/modal/modalWarningRePurchase.vue' /* webpackChunkName: "components/modal-warning-re-purchase" */).then(c => wrapFunctional(c.default || c))
export const OtpModal = () => import('../../components/modal/otpModal.vue' /* webpackChunkName: "components/otp-modal" */).then(c => wrapFunctional(c.default || c))
export const PopupImage = () => import('../../components/modal/popupImage.vue' /* webpackChunkName: "components/popup-image" */).then(c => wrapFunctional(c.default || c))
export const ProductNotify = () => import('../../components/modal/productNotify.vue' /* webpackChunkName: "components/product-notify" */).then(c => wrapFunctional(c.default || c))
export const WarningShipping = () => import('../../components/modal/warning-shipping.vue' /* webpackChunkName: "components/warning-shipping" */).then(c => wrapFunctional(c.default || c))
export const ListOrder = () => import('../../components/order/list-order.vue' /* webpackChunkName: "components/list-order" */).then(c => wrapFunctional(c.default || c))
export const ListProcess = () => import('../../components/order/list-process.vue' /* webpackChunkName: "components/list-process" */).then(c => wrapFunctional(c.default || c))
export const OrderItems = () => import('../../components/order/order-items.vue' /* webpackChunkName: "components/order-items" */).then(c => wrapFunctional(c.default || c))
export const DeliveryPrintingItems = () => import('../../components/printing/delivery-printing-items.vue' /* webpackChunkName: "components/delivery-printing-items" */).then(c => wrapFunctional(c.default || c))
export const ModalPreview = () => import('../../components/printing/modal-preview.vue' /* webpackChunkName: "components/modal-preview" */).then(c => wrapFunctional(c.default || c))
export const PrintingItems = () => import('../../components/printing/printing-items.vue' /* webpackChunkName: "components/printing-items" */).then(c => wrapFunctional(c.default || c))
export const QrComponent = () => import('../../components/qr/qr-component.vue' /* webpackChunkName: "components/qr-component" */).then(c => wrapFunctional(c.default || c))
export const QrConsent = () => import('../../components/qr/qr-consent.vue' /* webpackChunkName: "components/qr-consent" */).then(c => wrapFunctional(c.default || c))
export const QrImage = () => import('../../components/qr/qr-image.vue' /* webpackChunkName: "components/qr-image" */).then(c => wrapFunctional(c.default || c))
export const QrSuccess = () => import('../../components/qr/qr-success.vue' /* webpackChunkName: "components/qr-success" */).then(c => wrapFunctional(c.default || c))
export const RoleOrderList = () => import('../../components/role/role-order-list.vue' /* webpackChunkName: "components/role-order-list" */).then(c => wrapFunctional(c.default || c))
export const BlogItems = () => import('../../components/share/blog-items.vue' /* webpackChunkName: "components/blog-items" */).then(c => wrapFunctional(c.default || c))
export const CardProductSelect = () => import('../../components/share/card-product-select.vue' /* webpackChunkName: "components/card-product-select" */).then(c => wrapFunctional(c.default || c))
export const CompareProduct = () => import('../../components/share/compare-product.vue' /* webpackChunkName: "components/compare-product" */).then(c => wrapFunctional(c.default || c))
export const Consent = () => import('../../components/share/consent.vue' /* webpackChunkName: "components/consent" */).then(c => wrapFunctional(c.default || c))
export const CreateCostcenter = () => import('../../components/share/create-costcenter.vue' /* webpackChunkName: "components/create-costcenter" */).then(c => wrapFunctional(c.default || c))
export const DatePicker = () => import('../../components/share/date-picker.vue' /* webpackChunkName: "components/date-picker" */).then(c => wrapFunctional(c.default || c))
export const DonutCharts = () => import('../../components/share/donut-charts.vue' /* webpackChunkName: "components/donut-charts" */).then(c => wrapFunctional(c.default || c))
export const FilterControllerAlgolia = () => import('../../components/share/filter-controller-algolia.vue' /* webpackChunkName: "components/filter-controller-algolia" */).then(c => wrapFunctional(c.default || c))
export const FilterController = () => import('../../components/share/filter-controller.vue' /* webpackChunkName: "components/filter-controller" */).then(c => wrapFunctional(c.default || c))
export const ImageZoom = () => import('../../components/share/image-zoom.vue' /* webpackChunkName: "components/image-zoom" */).then(c => wrapFunctional(c.default || c))
export const InputQtySpecial = () => import('../../components/share/input-qty-special.vue' /* webpackChunkName: "components/input-qty-special" */).then(c => wrapFunctional(c.default || c))
export const InputQty = () => import('../../components/share/input-qty.vue' /* webpackChunkName: "components/input-qty" */).then(c => wrapFunctional(c.default || c))
export const InputText = () => import('../../components/share/input-text.vue' /* webpackChunkName: "components/input-text" */).then(c => wrapFunctional(c.default || c))
export const InstoryBanner = () => import('../../components/share/instory-banner.vue' /* webpackChunkName: "components/instory-banner" */).then(c => wrapFunctional(c.default || c))
export const ItemAddToCart = () => import('../../components/share/item-add-to-cart.vue' /* webpackChunkName: "components/item-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const Item = () => import('../../components/share/item.vue' /* webpackChunkName: "components/item" */).then(c => wrapFunctional(c.default || c))
export const ListAddress = () => import('../../components/share/list-address.vue' /* webpackChunkName: "components/list-address" */).then(c => wrapFunctional(c.default || c))
export const ListItemAdd = () => import('../../components/share/list-item-add.vue' /* webpackChunkName: "components/list-item-add" */).then(c => wrapFunctional(c.default || c))
export const ListItemCatalog = () => import('../../components/share/list-item-catalog.vue' /* webpackChunkName: "components/list-item-catalog" */).then(c => wrapFunctional(c.default || c))
export const ListOtherSeller = () => import('../../components/share/list-other-seller.vue' /* webpackChunkName: "components/list-other-seller" */).then(c => wrapFunctional(c.default || c))
export const ListQuestion = () => import('../../components/share/list-question.vue' /* webpackChunkName: "components/list-question" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/share/loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/share/modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const NewsItems = () => import('../../components/share/news-items.vue' /* webpackChunkName: "components/news-items" */).then(c => wrapFunctional(c.default || c))
export const Notfound = () => import('../../components/share/notfound.vue' /* webpackChunkName: "components/notfound" */).then(c => wrapFunctional(c.default || c))
export const PreconditionFailed = () => import('../../components/share/precondition-failed.vue' /* webpackChunkName: "components/precondition-failed" */).then(c => wrapFunctional(c.default || c))
export const ResponseFailed = () => import('../../components/share/response-failed.vue' /* webpackChunkName: "components/response-failed" */).then(c => wrapFunctional(c.default || c))
export const SearchSelect = () => import('../../components/share/search-select.vue' /* webpackChunkName: "components/search-select" */).then(c => wrapFunctional(c.default || c))
export const SelectBasic = () => import('../../components/share/select-basic.vue' /* webpackChunkName: "components/select-basic" */).then(c => wrapFunctional(c.default || c))
export const SelectDropdown = () => import('../../components/share/select-dropdown.vue' /* webpackChunkName: "components/select-dropdown" */).then(c => wrapFunctional(c.default || c))
export const SelectObject = () => import('../../components/share/select-object.vue' /* webpackChunkName: "components/select-object" */).then(c => wrapFunctional(c.default || c))
export const SkuItemAddToCart = () => import('../../components/share/sku-item-add-to-cart.vue' /* webpackChunkName: "components/sku-item-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const SliderBanners = () => import('../../components/share/slider-banners.vue' /* webpackChunkName: "components/slider-banners" */).then(c => wrapFunctional(c.default || c))
export const SliderItems = () => import('../../components/share/slider-items.vue' /* webpackChunkName: "components/slider-items" */).then(c => wrapFunctional(c.default || c))
export const SpecialDeal = () => import('../../components/share/special-deal.vue' /* webpackChunkName: "components/special-deal" */).then(c => wrapFunctional(c.default || c))
export const StripLo = () => import('../../components/share/strip-lo.vue' /* webpackChunkName: "components/strip-lo" */).then(c => wrapFunctional(c.default || c))
export const SwiperImage = () => import('../../components/share/swiper-image.vue' /* webpackChunkName: "components/swiper-image" */).then(c => wrapFunctional(c.default || c))
export const SwiperItem = () => import('../../components/share/swiper-item.vue' /* webpackChunkName: "components/swiper-item" */).then(c => wrapFunctional(c.default || c))
export const TabHeaderProductBrand = () => import('../../components/share/tab-header-product-brand.vue' /* webpackChunkName: "components/tab-header-product-brand" */).then(c => wrapFunctional(c.default || c))
export const TabProductBrand = () => import('../../components/share/tab-product-brand.vue' /* webpackChunkName: "components/tab-product-brand" */).then(c => wrapFunctional(c.default || c))
export const TabProductCategories = () => import('../../components/share/tab-product-categories.vue' /* webpackChunkName: "components/tab-product-categories" */).then(c => wrapFunctional(c.default || c))
export const ProductResult = () => import('../../components/skeleton/product-result.vue' /* webpackChunkName: "components/product-result" */).then(c => wrapFunctional(c.default || c))
export const SwiperSkeletonItem = () => import('../../components/skeleton/swiper-skeleton-item.vue' /* webpackChunkName: "components/swiper-skeleton-item" */).then(c => wrapFunctional(c.default || c))
export const CouponItem = () => import('../../components/suggest-coupon/couponItem.vue' /* webpackChunkName: "components/coupon-item" */).then(c => wrapFunctional(c.default || c))
export const CouponItemShop = () => import('../../components/suggest-coupon/couponItemShop.vue' /* webpackChunkName: "components/coupon-item-shop" */).then(c => wrapFunctional(c.default || c))
export const ModalCoupon = () => import('../../components/suggest-coupon/modalCoupon.vue' /* webpackChunkName: "components/modal-coupon" */).then(c => wrapFunctional(c.default || c))
export const ModalCouponDetail = () => import('../../components/suggest-coupon/modalCouponDetail.vue' /* webpackChunkName: "components/modal-coupon-detail" */).then(c => wrapFunctional(c.default || c))
export const AsideUser = () => import('../../components/template/aside-user.vue' /* webpackChunkName: "components/aside-user" */).then(c => wrapFunctional(c.default || c))
export const B2bNavbar = () => import('../../components/template/b2b-navbar.vue' /* webpackChunkName: "components/b2b-navbar" */).then(c => wrapFunctional(c.default || c))
export const BreadCrumbDepartment = () => import('../../components/template/bread-crumb-department.vue' /* webpackChunkName: "components/bread-crumb-department" */).then(c => wrapFunctional(c.default || c))
export const BreadCrumb = () => import('../../components/template/bread-crumb.vue' /* webpackChunkName: "components/bread-crumb" */).then(c => wrapFunctional(c.default || c))
export const FilterPanelLvl = () => import('../../components/template/filter-panel-lvl.vue' /* webpackChunkName: "components/filter-panel-lvl" */).then(c => wrapFunctional(c.default || c))
export const FilterPanel = () => import('../../components/template/filter-panel.vue' /* webpackChunkName: "components/filter-panel" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/template/footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Navbar = () => import('../../components/template/navbar.vue' /* webpackChunkName: "components/navbar" */).then(c => wrapFunctional(c.default || c))
export const OtpVerifyCopy = () => import('../../components/template/otp-verify-copy.vue' /* webpackChunkName: "components/otp-verify-copy" */).then(c => wrapFunctional(c.default || c))
export const OtpVerify = () => import('../../components/template/otp-verify.vue' /* webpackChunkName: "components/otp-verify" */).then(c => wrapFunctional(c.default || c))
export const Otp = () => import('../../components/template/otp.vue' /* webpackChunkName: "components/otp" */).then(c => wrapFunctional(c.default || c))
export const ProductPagination = () => import('../../components/template/product-pagination.vue' /* webpackChunkName: "components/product-pagination" */).then(c => wrapFunctional(c.default || c))
export const SecondNavbar = () => import('../../components/template/second-navbar.vue' /* webpackChunkName: "components/second-navbar" */).then(c => wrapFunctional(c.default || c))
export const BannerSwiper = () => import('../../components/shop-in-shop/display/banner-swiper.vue' /* webpackChunkName: "components/banner-swiper" */).then(c => wrapFunctional(c.default || c))
export const BrandsSwiper = () => import('../../components/shop-in-shop/display/brands-swiper.vue' /* webpackChunkName: "components/brands-swiper" */).then(c => wrapFunctional(c.default || c))
export const CouponSwiper = () => import('../../components/shop-in-shop/display/coupon-swiper.vue' /* webpackChunkName: "components/coupon-swiper" */).then(c => wrapFunctional(c.default || c))
export const FilterPanelBrand = () => import('../../components/template/algolia/filter-panel-brand.vue' /* webpackChunkName: "components/filter-panel-brand" */).then(c => wrapFunctional(c.default || c))
export const FilterPanelPrice = () => import('../../components/template/algolia/filter-panel-price.vue' /* webpackChunkName: "components/filter-panel-price" */).then(c => wrapFunctional(c.default || c))
export const MainNavbar = () => import('../../components/template/algolia/main-navbar.vue' /* webpackChunkName: "components/main-navbar" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
