export default function ({ $axios, app, store, redirect }, inject) {
  // Create a custom axios instance
  const api = $axios.create({
    headers: {
      common: {
        Accept: 'application/json, text/plain, */*',
      },
    },
  })
  // Set baseURL to something different //process.env.NUXT_ENV_BASE_URL
  api.setBaseURL(process.env.NUXT_ENV_BASE_URL)

  api.setHeader('Content-Type', 'application/json', [
    'post',
    'get',
    'put',
    'post',
    'delete',
    'patch',
    'head',
  ])
  api.setHeader(
    'Authorization',
    store.state.authen.accessToken !== ''
      ? `Bearer ${store.state.authen.accessToken}`
      : ''
  )
  api.onRequest((config) => {
    // console.log('config ', config)
    config.headers['Sale-Method-Id'] =
      store.state.user.profileType === 'eprocurement' ? 6 : 89
    config.headers['user-locale'] = app.i18n?.locale || 'th'

    store.dispatch('authen/isAuthenticated')
    if (!store.state.authen.isAuthenticated) {
      config.headers.Authorization = null
      if (store.state.authen.accessToken !== '') {
        store.dispatch('authen/signout')
        store.dispatch('user/removeUserProfile')
        // redirect(app.i18n.locale === 'th' ? '/biz' : '/en/biz')
      }
    } else {
      config.headers.Authorization = `Bearer ${store.state.authen.accessToken}`
    }
  })

  api.onRequestError((err) => {
    app.$bus.$emit('changeLoadingState', false)
  })

  api.onResponse((response) => {
    app.$bus.$emit('changeLoadingState', false)
  })

  api.onResponseError((err) => {
    app.$bus.$emit('changeLoadingState', false)
    // app.$bus.$emit('preconditionFailed', '')
    return Promise.reject(err)
  })

  api.onError((err) => {
    app.$bus.$emit('changeLoadingState', false)
    const code = parseInt(err.response && err.response.status)
    switch (code) {
      case 400:
        break
      case 401:
        return { err, data: {} }
      case 403:
        break
      case 404:
        return err.response
      case 412:
        if (
          !err.response.config.url.includes(
            'api/v1/Authentications/token/basic'
          ) &&
          !err.response.config.url.includes(
            'api/v2/Authentications/token/basic'
          ) &&
          !err.response.config.url.includes('api/v1/Credentials') &&
          !err.response.config.url.includes('api/v2/Credentials') &&
          !err.response.config.url.includes('api/v1/ProductSkus')
        ) {
          if (err.response.config.url.includes('biz-card/api/v1')) {
            app.$bus.$emit('responseFailed', err.response)
          } else {
            app.$bus.$emit('preconditionFailed', err.response)
          }
        }
        return err.response
      case 500:
        if (
          !err.response.config.url.includes(
            'authentication/api/v1/OneTimePasswords/requestotpchannel'
          )
        )
          app.$bus.$emit('responseFailed', err.response)
        return err.response
      default:
        break
    }
    return err.response
  })
  // Inject to context as $httpClient
  inject('httpClient', api)
}
