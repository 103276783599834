import * as core from '@/plugins/service/core'

export const profileProvider = {
  async checkEmail(email) {
    const data = core.head(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Users/CheckEmail?email=${email}`
    )
    return data
  },
  async register(payload) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Users`,
      payload
    )
    return data
  },
  async registerV2(payload) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v2/Users`,
      payload
    )
    return data
  },
  async verifyUserByOTP(payload) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Users/otp`,
      payload
    )
    return data
  },
  async syncUser(payload) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Users/CreateUserByToken`,
      payload
    )
    return data
  },
  async getUserData() {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Me/Info`
    )
    return data
  },
  async searchCustomerList(payload) {
    // const data = core.fetch(
    //   `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/BackEndCustomers`,
    //   params
    // )
    // return data
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/BackEndCustomers/query`,
      payload
    )
    return data
  },
  async updateUserProfile(payload) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/BackEndCustomers/Mapping`,
      payload
    )
    return data
  },
  async getUserDataById(id, showLoading = true) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Me/master`,
      null,
      null,
      showLoading
    )
    return data
  },
  // async getAccountMasterById(accountId, useropenid) {
  //   const data = core.fetch(
  //     `${process.env.NUXT_ENV_SERVICE_MIDDLEWARE}api/v1/profiles/${accountId}/users/${useropenid}`
  //   )
  //   return data
  // },
  async getAccountMasterById(accountId) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Accounts/${accountId}/usermaster`
    )
    return data
  },
  async updateUserProfileMaster(id, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Me/accounts/${id}`,
      params
    )
    return data
  },
  async updateProfileThe1Card(id, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Me/the1card/${id}`,
      params
    )
    return data
  },
  async getUserCompanyDataById(useropenid, companyid) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Me/companies/${companyid}`
    )
    return data
  },
  async updateMeCompaniesMaster(useropenid, companyid, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Me/companies/${companyid}`,
      params
    )
    return data
  },
  async updateUserCompaniesMaster(useropenid, companyid, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Users/${useropenid}/companies/${companyid}`,
      params
    )
    return data
  },
  async createBackendCustomer(payload) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/BackEndCustomers`,
      payload
    )
    return data
  },
  async getUserCompanyRequesterlineDataById(useropenid, companyid) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Me/companies/${companyid}/requesterline`
    )
    return data
  },
  async getAllShippingDataById(accountid) {
    const data = core.fetch(
      `${
        process.env.NUXT_ENV_SERVICE_PROFILE
      }api/v1/Me/accounts/${accountid}/shippings?pageNo=${1}&pageSize=${9999}`
    )
    return data
  },

  async updateShippingStatusDataById(accountid, shippingid, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Me/accounts/${accountid}/shippings/${shippingid}/status`,
      params
    )
    return data
  },
  async getShippingDataById(accountid, shippingid) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Me/accounts/${accountid}/shippings/${shippingid}`
    )
    return data
  },
  async updateShippingData(accountid, shippingid, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Me/accounts/${accountid}/shippings/${shippingid}`,
      params
    )
    return data
  },
  async updatePassword(payload) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Me/password`,
      payload
    )
    return data
  },
  async updatePasswordV2(payload) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v2/Me/password`,
      payload
    )
    return data
  },
  async createShippingData(accountid, params) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Me/accounts/${accountid}/shipping`,
      params
    )
    return data
  },
  async updateShippingDefaultDataById(accountid, shippingid, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Me/accounts/${accountid}/shippings/${shippingid}/default`,
      params
    )
    return data
  },
  async getAllInvoiceDataById(accountid) {
    const data = core.fetch(
      `${
        process.env.NUXT_ENV_SERVICE_PROFILE
      }api/v1/Me/accounts/${accountid}/invoices?pageNo=${1}&pageSize=${9999}`
    )
    return data
  },
  async getInvoiceDataById(accountid, invoiceid) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Me/accounts/${accountid}/invoices/${invoiceid}`
    )
    return data
  },
  async updateInvoiceDataById(accountid, invoiceid, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Me/accounts/${accountid}/invoices/${invoiceid}`,
      params
    )
    return data
  },
  async createInvoiceData(accountid, params) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Me/accounts/${accountid}/invoice`,
      params
    )
    return data
  },
  async updateInvoiceStatusDataById(accountid, invoiceid, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Me/accounts/${accountid}/invoices/${invoiceid}/status`,
      params
    )
    return data
  },
  async updateInvoiceDefaultDataById(accountid, invoiceid, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Me/accounts/${accountid}/invoices/${invoiceid}/default`,
      params
    )
    return data
  },
  async getCostcentList(companyid, useropenid) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/${companyid}/costcenters/user/${useropenid}`
    )
    return data
  },
  async getCompaniesDataById(companyid) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/${companyid}/Info`
    )
    return data
  },
  async updateCompaniesInfoDataById(comanyid, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/${comanyid}/info`,
      params
    )
    return data
  },
  async updateCompaniesSettingDataById(comanyid, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/${comanyid}/infosetting`,
      params
    )
    return data
  },
  async uploadCenterImage(apiData, config) {
    const data = core.upload(
      `${process.env.NUXT_ENV_SERVICE_CLOUD}api/v1/Files`,
      apiData,
      config
    )
    return data
  },
  async UpdateCompaniesImageUrlData(comanyid, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/${comanyid}/imageurl`,
      params
    )
    return data
  },
  async UpdateProfileImageUrlData(useropenid, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Me/imageurl/base64url`,
      params
    )
    return data
  },
  async getCompaniesLevelControlData(
    company_id,
    department_status,
    costcenter_status
  ) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/${company_id}/departments?status_department=${department_status}&status_costcenter=${costcenter_status}`,
      null,
      null,
      false
    )
    return data
  },
  async createDepartmentData(company_id, params) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/${company_id}/departments`,
      params,
      null,
      false
    )
    return data
  },
  async UpdateDepartmentDataById(company_id, department_id, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/${company_id}/departments/${department_id}`,
      params
    )
    return data
  },
  async getDepartmentDataById(company_id, department_id) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/${company_id}/departments?name_department=${department_id}`
    )
    return data
  },
  async createCostcenterDataById(company_id, params) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/${company_id}/costcenter`,
      params
    )
    return data
  },
  async getCoscenterDataById(company_id, costcenter_id) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/${company_id}/costcenter/${costcenter_id}`
    )
    return data
  },
  async getCompaniesRequesterLineByUser(companyId, userOpenId) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Requesterlines/${companyId}/user/${userOpenId}`
    )
    return data
  },
  async getCompaniesControlBudget(companyId, costcenterId, departmentId) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/${companyId}/budgets?costcenter_id=${costcenterId}&department_id=${departmentId}`
    )
    return data
  },
  async updateCostCenterDataById(company_id, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/${company_id}/costcenter`,
      params
    )
    return data
  },
  async getCompaniesRoleMasterData(user_open_id, company_id) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Users/${user_open_id}/companies/${company_id}/roles`
    )
    return data
  },
  async getCompaniesUserRolesDataById(company_id) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/${company_id}/users/rolesinfo`
    )
    return data
  },
  async updateUserCompaniesRoleById(user_open_id, company_id, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Users/${user_open_id}/companies/${company_id}/Role`,
      params
    )
    return data
  },
  async updateUserCompaniesRoleAdminById(company_id, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Users/${company_id}/RoleAdmin`,
      params
    )
    return data
  },
  async getCompaniesRolesMasterAll(company_id) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/GetCompaniesRoleMaster?company_id=${company_id}`
    )
    return data
  },
  async createCompaniesUserData(company_id, params) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/${company_id}/companiesuser`,
      params
    )
    return data
  },
  async getRequesterLineDataById(company_id, user_open_id) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/RequesterLines/${company_id}/user/${user_open_id}`
    )
    return data
  },
  async getCostCenterDataById(company_id, user_open_id) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/RequesterLines/${company_id}/user/${user_open_id}/costcenters`
    )
    return data
  },
  async getRoleApproverEmailById(company_id, is_loanding = true) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Users/Companies/${company_id}/roleapproveremails`,
      null,
      null,
      is_loanding
    )
    return data
  },
  async createRequesterLinesData(params) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/RequesterLines`,
      params
    )
    return data
  },
  async getRequesterLineCostcenterDataById(requester_id) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/RequesterLines/${requester_id}/approval`
    )
    return data
  },
  async updateApproval(requester_id, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/RequesterLines/${requester_id}`,
      params
    )
    return data
  },
  async deleteRequesterLineById(requester_id) {
    const data = core.remove(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/RequesterLines/${requester_id}`
    )
    return data
  },
  async getAccountUsersAll(account_id) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Accounts/${account_id}/Users`
    )
    return data
  },
  async updateAccountUsersById(account_id, user_open_id, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Accounts/${account_id}/Users/${user_open_id}`,
      params
    )
    return data
  },
  async createUserDataById(account_id, params) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Accounts/${account_id}/User`,
      params
    )
    return data
  },
  async getAccountUsersById(account_id, user_open_id) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Accounts/${account_id}/Users/${user_open_id}`
    )
    return data
  },
  async getCompaniesProductskusData(query) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/SearchProductControl`,
      query
    )
    return data
  },
  async getNameCostcenterData(company_id) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/${company_id}/namecostcenters`,
      null,
      null,
      false
    )
    return data
  },
  async getNameDepartmentData(company_id) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/${company_id}/namedepartments`,
      null,
      null,
      false
    )
    return data
  },

  async updateCostcenterById(company_id, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/${company_id}/productskus`,
      params
    )
    return data
  },
  async delectCostcenterDataById(company_id, costcenter_id) {
    const data = core.remove(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/${company_id}/costcenter/${costcenter_id}/productskus`
    )
    return data
  },
  async createProductControlData(company_id, params) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/${company_id}/productskus`,
      params
    )
    return data
  },
  async getฺBannerHomeData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v2/Contents/banners/homes`,
      params,
      null,
      false
    )
    return data
  },
  async getBannerCategoryData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v2/Contents/banners/categorys`,
      params,
      null,
      false
    )
    return data
  },
  async checkproductskusData(company_id, sku_code) {
    const data = core.head(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/${company_id}/productskus/${sku_code}`
    )
    return data
  },
  async delectproductskusById(company_id, sku_code) {
    const data = core.remove(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/${company_id}/productskus/${sku_code}`
    )
    return data
  },
  async createUserAccountDataById(params) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Users/account`,
      params
    )
    return data
  },
  async createUserAccountDataByIdV2(params) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v2/Users/account`,
      params
    )
    return data
  },
  async getBrandsHomeData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v2/Contents/banners/brands`,
      params,
      null,
      false
    )
    return data
  },
  async updateUserLastActive(userOpenId, body) {
    const data = core.edit(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Me/lastactive`,
      body
    )
    return data
  },
  async checkCostCenterData(params) {
    const data = core.head(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Companies/costcenters`,
      params,
      null,
      false
    )
    return data
  },
  async checkCompanyEmail(company_id, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/companies/${company_id}/user`,
      params,
      null,
      false
    )
    return data
  },
  async checkCompany(company_id) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/companies/${company_id}`,
      null,
      null,
      false
    )
    return data
  },
  async getBannerCampaignsData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/contents/banners/campaigns`,
      params,
      null,
      false
    )
    return data
  },
  async getBrandAllData() {
    const data = core.fetchCache(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Contents/brand/seeall`,
      null,
      null,
      false
    )
    return data
  },
  async getRoleRequestersEmailById(company_id) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Users/Companies/${company_id}/rolerequesters`,
      null,
      null,
      false
    )
    return data
  },
  async getNewsData(company_id, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/News/${company_id}`,
      params,
      null,
      false
    )
    return data
  },
  async uploadContentImage(formData) {
    const data = await core.upload(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Files/content/images`,
      formData
    )
    return data
  },
  async updateContentImage(news_id, params) {
    const data = core.update(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Contents/${news_id}/news`,
      params
    )
    return data
  },
  async createContentData(params) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Contents/news`,
      params
    )
    return data
  },
  async getNewsDashboardData(company_id, params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/News/${company_id}/dashboard`,
      params,
      null,
      false
    )
    return data
  },
  async getUserConsetStatus(userOpenId, email) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Me/consent/status?email=${email}&user_open_id=${userOpenId}`,
      null,
      null,
      false
    )
    return data
  },
  async createUserConsentStatus(payload) {
    const axios = require('axios')
    const data = axios({
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'Post',
      data: payload,
      baseURL: process.env.NUXT_ENV_BASE_URL,
      url: `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Users/consent`,
    })
    // const data = core.create(
    //   `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Users/consent`,
    //   payload,
    //   null,
    //   false
    // )
    return data
  },
  async getAccountMaster(accountId) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v1/Accounts/${accountId}`
    )
    return data
  },
  async getฺBannerPageData(params) {
    const data = core.fetch(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v2/Contents/banners/page`,
      params,
      null,
      false
    )
    return data
  },
  async createUserVerify(otp_prove_token, body) {
    const axios = require('axios')
    const data = axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + otp_prove_token,
      },
      method: 'post',
      data: body,
      baseURL: process.env.NUXT_ENV_BASE_URL,
      url: `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v2/Users/verifies`,
    })
    return data
  },
  async getOtpVerify(verify_key) {
    const axios = require('axios')
    const data = axios({
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + verify_key,
      },
      method: 'get',
      // data: body,
      baseURL: process.env.NUXT_ENV_BASE_URL,
      url: `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v2/Users/otpverify`,
    })
    return data
    // const data = core.create(
    //   `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v2/Users/otpverify`,
    //   payload
    // )
    // return data
  },
  async checkInvite(payload) {
    const data = core.create(
      `${process.env.NUXT_ENV_SERVICE_PROFILE}api/v2/Users/checkinvite`,
      payload
    )
    return data
  },
}
export default (context, inject) => {
  inject('profileProvider', profileProvider)
}
